import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CustomModal = ({ open, onClose, title, content, onProceed, blur}) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={blur ? onClose : undefined}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      disableEscapeKeyDown={blur}
      sx={{
        backdropFilter: blur ? 'blur(5px)' : 'none',
      }}
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {xs: '90%', sm: '80%', md: 600},
        maxHeight: '90vh',
        overflowY: 'auto',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: {xs: 2, sm: 3, md: 4},
        borderRadius: '10px',
        borderStyle: 'solid',
        borderWidth: '3px',
        borderImage: 'linear-gradient(to right, #f93b53 0%, #ff3d4e 7.5%, #dc2f6e 75%, #bb228b 100%) 1',
      }}>
        <Typography id="modal-title" variant="h6" component="h2" sx={{fontSize: {xs: '1.1rem', sm: '1.25rem'}}}>
          {title}
        </Typography>
        {content}
        {onProceed && !blur && (
          <Box sx={{ display: 'flex', flexDirection: {xs: 'column', sm: 'row'}, justifyContent: 'flex-end', mt: 2, gap: 2 }}>
            <Button onClick={onClose} variant="outlined" color="error" fullWidth={true} sx={{mb: {xs: 1, sm: 0}}}>
              {t('annuler')}
            </Button>
            <Button onClick={onProceed} variant="contained" color="success" fullWidth={true}>
              {t('valider')}
            </Button>
          </Box>
        )}
        {blur && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button onClick={onProceed} variant="contained" color="primary" fullWidth={true}>
              {t('return_page_match')}
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default CustomModal;