import styled from 'styled-components';
import { Button as MuiButton, Skeleton, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { createTheme } from '@mui/material/styles';

export const Container = styled.div`
    width: 90%;
    max-width: ${props => props.hasLitigeData ? '1600px' : '1200px'};
    margin: 0 auto;
    text-align: center;
    background-color: #1E1E1E;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
`;

export const LogoContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
    color: #E0E0E0;
`;

export const TeamBlock = styled.div`
    background-color: #232222;
    border-radius: 10px;
    padding: 15px;
    width: 180px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    position: relative;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        width: 140px;
        padding: 10px;
    }

    &::before {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        background: linear-gradient(to right, #f93b53 0%, #ff3d4e 7.5%, #dc2f6e 75%, #bb228b 100%);
        border-radius: 12px;
        z-index: -1;
    }
`;

export const TeamInfo = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Logo = styled.img`
    width: 100px;
    height: 100px;
    object-fit: contain;

    @media (max-width: 768px) {
        width: 80px;
        height: 80px;
    }
`;

export const LogoSkeleton = styled(Skeleton)`
    width: 100px;
    height: 100px;

    @media (max-width: 768px) {
        width: 80px;
        height: 80px;
    }
`;

export const TeamTag = styled.p`
    margin: 10px 0 0;
    font-weight: bold;
    font-size: 1.1rem;
    color: #E0E0E0;

    @media (max-width: 768px) {
        font-size: 0.9rem;
    }
`;

export const TeamStatus = styled.p`
    margin: 5px 0 0;
    font-style: italic;
    font-size: 0.8rem;
    color: #A0A0A0;

    @media (max-width: 768px) {
        font-size: 0.7rem;
    }
`;

export const StyledButton = styled(MuiButton)`
    background: linear-gradient(to right, #f93b53 0%, #ff3d4e 7.5%, #dc2f6e 75%, #bb228b 100%);
    color: #FFFFFF;
    padding: 10px 20px;
    font-size: 1.0rem;
    transition: background 0.3s;
    margin-top: 20px;
`;

export const MatchDetails = styled.div`
    margin-top: 20px;
    color: #E0E0E0;
    text-align: left;
`;

export const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

export const StyledAccordion = styled(Accordion)`
    background-color: #2C2C2C;
    color: #E0E0E0;
    margin-bottom: 50px;

    &.Mui-expanded {
        margin-top: 0;
        margin-bottom: 10px;
    }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
    background-color: #3C3C3C;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.5);
    .MuiAccordionSummary-content {
        margin: 10px 0;
    }
    .MuiSvgIcon-root {
        color: #E0E0E0;
    }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
    padding: 0;
`;

export const TextBlock = styled.div`
    flex: 1;
    margin-top: 30px;
    padding: 20px;
    background-color: #2C2C2C;
    border-radius: 10px;
    color: #E0E0E0;
    text-align: left;

    @media (min-width: 768px) {
        margin-top: 0;
    }
`;

export const ImportantInfo = styled.div`
    padding: 15px;
    background-color: #3C3C3C;
    border-left: 5px solid #f93b53;
    border-radius: 5px;
`;

export const ImportantInfo2 = styled.div`
    padding: 15px;
    background-color: #3C3C3C;
    border-left: 5px solid #5bbbff;
    border-radius: 5px;
    margin-bottom: 10px;
`;

export const InfoTitle = styled.h4`
    color: #f93b53;
    margin-bottom: 10px;
`;

export const InfoList = styled.ul`
    padding-left: 20px;
    list-style-type: disc;
`;

export const InfoListItem = styled.li`
    margin-bottom: 10px;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    
    @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const MainContent = styled.div`
    flex: 1;
    margin-right: 0;
    margin-bottom: 20px;

    @media (min-width: 768px) {
        margin-right: 20px;
        margin-bottom: 0;
    }
`;
