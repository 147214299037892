import React, { useEffect, useState } from 'react';
import { loginWithToken } from '../services/api';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { 
  Button, 
  Container, 
  Paper, 
  List, 
  ListItem, 
  ListItemText,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Chip,
  IconButton
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/fr';
import CircularProgress from '@mui/material/CircularProgress';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Modal from '@mui/material/Modal';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import LinearProgress from '@mui/material/LinearProgress';

const StyledContainer = styled(Container)`
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin: 1rem auto;
  max-width: 95% !important;
`;

const StyledPaper = styled(Paper)`
  padding: 2rem;
  margin: 1rem 0;
`;

const ActionContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin: 2rem 0;
`;

const StyledListItem = styled(ListItem)`
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem 0;
`;

const UserInfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-top: 1rem;
`;

const StyledChip = styled(Chip)`
  margin: 0 4px;
  background-color: ${props => props.variant === "outlined" ? "rgba(255, 255, 255, 0.1)" : "inherit"};
  color: ${props => props.variant === "outlined" ? "#fff" : "inherit"};
  border-color: rgba(255, 255, 255, 0.3);

  &.MuiChip-outlined {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const UserDetailsBox = styled(Box)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  padding: 12px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  margin-top: 8px;
  position: relative;
`;

const BanButton = styled(Button)`
  position: absolute;
  right: 24px;
  min-width: 100px;
`;

const HighlightedChip = styled(StyledChip)`
  background-color: rgba(144, 202, 249, 0.15) !important;
  border-color: #90caf9 !important;
  font-weight: 500;
  
  .MuiChip-label {
    color: #90caf9;
  }
`;

const FullScreenDialog = styled(Dialog)`
  .MuiDialog-paper {
    margin: 0;
    max-width: none;
    width: 100%;
    height: 100%;
    max-height: none;
    border-radius: 0;
  }
`;

const ImageModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  
  img {
    max-width: 90vw;
    max-height: 90vh;
    object-fit: contain;
  }
`;

const DataSection = styled(Paper)`
  margin: 16px 0;
  padding: 24px;
  background-color: #1e1e1e;
  color: #fff;
  max-height: 600px;
  overflow-y: auto;
  font-family: 'Consolas', monospace;
  
  pre {
    margin: 0;
    white-space: pre-wrap;
  }

  .data-item {
    background-color: rgba(255, 255, 255, 0.05);
    padding: 16px;
    margin-bottom: 16px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  .data-row {
    display: grid;
    grid-template-columns: 150px 1fr;
    gap: 8px;
    padding: 4px 0;
  }

  .data-label {
    color: #90caf9;
    font-weight: 500;
  }

  .data-value {
    color: rgba(255, 255, 255, 0.85);
  }
`;

const StyledImageList = styled(ImageList)`
  width: 100%;
  padding: 16px;
  background-color: #1e1e1e;
  border-radius: 8px;
  
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
  }
`;

const StyledImageListItem = styled(ImageListItem)`
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
  width: 140px !important;
  height: 180px !important;
  
  &:hover {
    transform: scale(1.05);
  }
  
  .time-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 4px 8px;
    font-size: 0.8rem;
    font-family: 'Consolas', monospace;
  }

  img {
    width: 140px !important;
    height: 180px !important;
    object-fit: cover !important;
  }
`;

const HardwareSection = styled(Paper)`
  margin: 16px 0;
  padding: 24px;
  background-color: #1e1e1e;
  color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
`;

const HardwareGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 24px;

  @media (min-width: 960px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const HardwareItem = styled(Paper)`
  background-color: rgba(255, 255, 255, 0.08);
  padding: 16px;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  border: 1px solid rgba(255, 255, 255, 0.1);
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.12);
  }
  
  h4 {
    margin: 0 0 12px 0;
    color: #90caf9;
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  p {
    margin: 8px 0;
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.85);
    word-break: break-word;
    line-height: 1.5;
  }
`;

const StatusSection = styled(Paper)`
  margin: 16px 0;
  padding: 16px;
  background-color: #1e1e1e;
  border-radius: 8px;
  display: flex;
  gap: 16px;
`;

const StatusCard = styled(Paper)`
  flex: 1;
  padding: 16px;
  background-color: ${props => props.active ? 'rgba(102, 187, 106, 0.1)' : 'rgba(255, 107, 107, 0.1)'};
  border: 1px solid ${props => props.active ? 'rgba(102, 187, 106, 0.3)' : 'rgba(255, 107, 107, 0.3)'};
  border-radius: 8px;
  
  .status-title {
    color: #fff;
    font-size: 1.1rem;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .status-value {
    font-size: 1.2rem;
    font-weight: 500;
    color: ${props => props.active ? '#66bb6a' : '#ff6b6b'};
  }
`;

const InfoHighlight = styled(Typography)`
  color: #90caf9;
  font-weight: 500;
  background-color: rgba(144, 202, 249, 0.15);
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #90caf9;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const IPSection = styled(Box)`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  gap: 12px;
`;

const IPCard = styled(Paper)`
  background: linear-gradient(135deg, rgba(25, 118, 210, 0.05) 0%, rgba(25, 118, 210, 0.1) 100%);
  padding: 16px 24px;
  border: 1px solid rgba(25, 118, 210, 0.2);
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: all 0.2s ease-in-out;
  position: relative;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(25, 118, 210, 0.15);
    border-color: rgba(25, 118, 210, 0.3);
  }
  
  .ip-label {
    color: #90caf9;
    font-weight: 500;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    min-width: 100px;
  }
  
  .ip-value {
    color: #fff;
    font-family: 'Consolas', monospace;
    font-size: 1.1rem;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
`;

const WarningBanner = styled(Box)`
  background: linear-gradient(45deg, #ff4444 30%, #ff1111 90%);
  color: white;
  padding: 8px 16px;
  border-radius: 4px 4px 0 0;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const SuspiciousHighlight = styled.span`
  background-color: rgba(255, 68, 68, 0.2);
  padding: 2px 4px;
  border-radius: 2px;
  font-weight: bold;
  color: #ff4444;
`;

const CheatDetectedBanner = styled(Box)`
  background: linear-gradient(-45deg, #ff0000, #ff4444, #ff0000, #ff4444);
  background-size: 400% 400%;
  animation: gradientBG 3s ease infinite;
  color: white;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  font-size: 1.2rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 12px rgba(255, 0, 0, 0.3);

  @keyframes gradientBG {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }

  svg {
    animation: pulse 1s ease-in-out infinite;
  }

  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
  }
`;

const WarningIcon = styled.span`
  font-size: 24px;
  animation: shake 0.5s ease-in-out infinite;

  @keyframes shake {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(10deg); }
    75% { transform: rotate(-10deg); }
    100% { transform: rotate(0deg); }
  }
`;

const SUSPICIOUS_KEYWORDS = {
    process: [
        'cronus', 'zen', 'titan', 'rewasd', 'xim', 'ds4windows', 'inputmapper', 
        'vjoy', 'emulator', 'macro', 'script', 'autohotkey', 'turboing', 
        'virtual', 'remap'
    ],
    network: [
        'cronus.com', 'cronusmax.com', 'consoletuner.com', 'xim-tech.com',
        'ds4windows.com', 'inputmapper.com', 'titan.com'
    ],
    controllers: [
        'Virtual', 'Emulated', 'Emulator', 'vJoy', 'vController', 'vGamepad',
        'Virtual Gamepad', 'Virtual Controller', 'Virtual Device', 'Virtual HID',
        'Emulated Controller', 'Emulated Gamepad', 'Emulated Device',
        
        'Cronus', 'Zen', 'XIM', 'Titan', 'Brook', 'MaxShoot',
        'CronusMax', 'TitanTwo', 'XIM Apex', 'XIM4',
        
        'reWASD', 'DS4Windows', 'InputMapper', 'JoyToKey',
        'x360ce', 'Xpadder', 'AntiMicro', 'Joy2Key',
        
        'Input Redirector', 'Input Converter',
        'Controller Emulator', 'Gamepad Emulator',
        
        'Macro', 'Script', 'Remap', 'Turbo', 'Rapid Fire',
        'Modified', 'Unlicensed'
    ]
};

const LEGITIMATE_DEVICES = [
    'usb hub',
    'concentrateur usb',
    'hub usb standard',
    'generic usb hub',
    'root hub',
    'composite device',
    'périphérique composite',
    'standard hub',
    'lightspeed',
    'logitech',
    'receiver',
    'wireless receiver',
    'contrôleur hôte usb standard',
    'standard usb host controller',
    'root device',
    'routeur racine',
    'usb root',
    'usb4 root',
    'root_device_router',
    'périphérique usb composite',
    'usb composite device',
    'appareil générique usb',
    'dispositif routeur',
    'contrôleur d\'hôte compatible xhci',
    'contrôleur d\'hôte xhci',
    'intel(r) usb',
    'microsoft',
    'extensible'
];

const isItemSuspicious = (item, type) => {
    const itemStr = JSON.stringify(item).toLowerCase();
    return SUSPICIOUS_KEYWORDS[type].some(keyword => 
        itemStr.includes(keyword.toLowerCase())
    );
};

const isControllerSuspicious = (device) => {
    const deviceStr = JSON.stringify(device).toLowerCase();
    
    if (LEGITIMATE_DEVICES.some(device => deviceStr.includes(device.toLowerCase()))) {
        return false;
    }

    return SUSPICIOUS_KEYWORDS.controllers.some(keyword => 
        deviceStr.toLowerCase().includes(keyword.toLowerCase())
    );
};

const StatusChip = styled(Chip)`
  margin: 0 4px;
  font-weight: 500;
  
  &.charlie-delta-active {
    background: linear-gradient(-45deg, #ff0000, #ff4444, #ff0000, #ff4444);
    background-size: 400% 400%;
    animation: gradientBG 3s ease infinite;
    color: white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.3);
    border: none;
    font-weight: bold;
    
    @keyframes gradientBG {
      0% { background-position: 0% 50%; }
      50% { background-position: 100% 50%; }
      100% { background-position: 0% 50%; }
    }
  }

  &.normal {
    background-color: rgba(255, 255, 255, 0.1);
    color: #fff;
  }
`;

const StyledBox = styled(Box)`
  &:not(:last-child) {
    margin-bottom: 2rem;  // Ajoute une marge en bas de chaque Box sauf la dernière
    padding-bottom: 2rem; // Ajoute un padding en bas
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); // Ajoute une ligne de séparation
  }
`;

// Ajout du style pour la liste de fichiers
const FileListContainer = styled(Paper)`
  margin: 16px 0;
  background-color: #1e1e1e;
  color: #fff;
`;

const FileListItem = styled(ListItem)`
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }
  
  .file-info {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .file-time {
    color: #90caf9;
    font-family: 'Consolas', monospace;
    min-width: 100px;
  }
  
  .file-name {
    color: rgba(255, 255, 255, 0.85);
  }
`;

const SkedAC = () => {
    const { userToken } = useParams();
    const [user, setUser] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({});
    const [selectedImage, setSelectedImage] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const [screenshots, setScreenshots] = useState([]);
    const [networkData, setNetworkData] = useState([]);
    const [processData, setProcessData] = useState([]);
    const [controllersData, setControllersData] = useState([]);
    const [hardwareDetails, setHardwareDetails] = useState(null);
    const [loadingImages, setLoadingImages] = useState({});
    const [accounts, setAccounts] = useState([]);
    const [isRefreshing, setIsRefreshing] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const userData = await loginWithToken(userToken);
                if (userData.status === 'error') {
                    alert('Token expiré');
                    window.location.href = 'https://app.sked.gg/';
                } else {
                    if(userData?.data?.user_admin === 0) {
                        window.location.href = 'https://app.sked.gg/';
                    } else {
                        const authorizedUsers = ['Benjxmin', 'Reborn', 'iKayzL'];
                        if (!authorizedUsers.includes(userData?.data?.user_nickname)) {
                            window.location.href = 'https://app.sked.gg/';
                        } else {
                            setUser(userData);
                        }
                    }
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des données', error);
                window.location.href = 'https://app.sked.gg/';
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [userToken]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const formattedDate = selectedDate.format('YYYY-MM-DD');
            const response = await fetch(`https://arbitrage-api.sked.gg/sked-ac/users-by-date?date=${formattedDate}`);
            const data = await response.json();
            
            if (data.success) {
                setUsers(data.users);
                console.log('Détails des utilisateurs:', data.users);
                await Promise.all(data.users.map(user => fetchUserDetails(user.nickname)));
                
            } else {
                console.error('Erreur lors de la récupération des utilisateurs');
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des utilisateurs', error);
        } finally {
            setLoading(false);
        }
    };

    const handleUserClick = (user) => {
        setSelectedUser(user);
        setOpenDialog(true);
        loadHardwareDetails(user.nickname, selectedDate.format('YYYY-MM-DD'));
        loadDetailedData(user.nickname, selectedDate.format('YYYY-MM-DD'));
    };

    const fetchUserDetails = async (nickname) => {
        setIsRefreshing(true);
        try {
            const response = await fetch('https://arbitrage-api.sked.gg/sked-ac/user/find', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ nickname: nickname })
            });
            
            const data = await response.json();
            
            if (data.success) {
                setUserDetails(prevDetails => {
                    const newDetails = {
                        ...prevDetails,
                        [nickname]: data.user
                    };
                    return newDetails;
                });
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des détails:', error.message);
        } finally {
            setIsRefreshing(false);
        }
    };

    const loadDetailedData = async (nickname, date) => {
        try {
            const screenshotsResponse = await fetch(`https://arbitrage-api.sked.gg/sked-ac/screenshots?date=${date}&nickname=${nickname}`);
            const screenshotsData = await screenshotsResponse.json();
            
            if (screenshotsData.success) {
                const formattedScreenshots = screenshotsData.screenshots
                    .map(screenshot => ({
                        ...screenshot,
                        thumbnailUrl: `https://arbitrage-api.sked.gg${screenshot.url}?size=thumbnail`,
                        fullUrl: `https://arbitrage-api.sked.gg${screenshot.url}`,
                        formattedTime: new Date(screenshot.timestamp * 1000).toLocaleTimeString('fr-FR')
                    }))
                    .sort((a, b) => b.timestamp - a.timestamp); // Tri par timestamp décroissant
                setScreenshots(formattedScreenshots);
            }

            // Processus
            const processesResponse = await fetch(`https://arbitrage-api.sked.gg/sked-ac/processes?date=${date}&nickname=${nickname}`);
            const processesData = await processesResponse.json();
            if (processesData.success) {
                setProcessData(processesData.processes);
            }

            // Réseau
            const networkResponse = await fetch(`https://arbitrage-api.sked.gg/sked-ac/network?date=${date}&nickname=${nickname}`);
            const networkData = await networkResponse.json();
            if (networkData.success) {
                setNetworkData(networkData.network);
            }

            // Périphériques
            const devicesResponse = await fetch(`https://arbitrage-api.sked.gg/sked-ac/devices?date=${date}&nickname=${nickname}`);
            const devicesData = await devicesResponse.json();
            if (devicesData.success) {
                setControllersData(devicesData.devices);
            }

        } catch (error) {
            console.error('Erreur lors du chargement des données détaillées:', error);
        }
    };

    const loadHardwareDetails = async (nickname, date) => {
        try {
            const response = await fetch(`https://arbitrage-api.sked.gg/sked-ac/hardware-details?date=${date}&nickname=${nickname}`);
            const data = await response.json();
            if (data.success) {
                setHardwareDetails(data.hardwareInfo);
            }
        } catch (error) {
            console.error('Erreur lors du chargement des détails hardware:', error);
        }
    };

    const handleBanUser = async () => {
        try {
            const endpoint = userDetails[selectedUser.nickname]?.banned 
                ? 'https://arbitrage-api.sked.gg/sked-ac/unban-user'
                : 'https://arbitrage-api.sked.gg/sked-ac/ban-user';

            console.log('État actuel banned:', userDetails[selectedUser.nickname]?.banned);
            console.log('Endpoint utilisé:', endpoint);
            console.log('IP MAC envoyée:', userDetails[selectedUser.nickname]?.ipMac);

            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    ipMac: userDetails[selectedUser.nickname]?.ipMac
                })
            });
            
            const data = await response.json();
            console.log('Réponse du serveur:', data);
            
            if (data.success) {
                setUserDetails(prevDetails => ({
                    ...prevDetails,
                    [selectedUser.nickname]: {
                        ...prevDetails[selectedUser.nickname],
                        banned: !prevDetails[selectedUser.nickname].banned
                    }
                }));
            } else {
                console.error('Erreur lors de l\'opération:', data.message);
            }
        } catch (error) {
            console.error('Erreur lors de l\'opération:', error);
        }
    };

    const handleResetCharlieDelta = async () => {
        try {
            const response = await fetch('https://arbitrage-api.sked.gg/sked-ac/reset-charlie-delta', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 
                    nickname: selectedUser.nickname 
                })
            });
            
            const data = await response.json();
            
            if (data.success) {
                // Mise à jour du state local
                setUserDetails(prevDetails => ({
                    ...prevDetails,
                    [selectedUser.nickname]: {
                        ...prevDetails[selectedUser.nickname],
                        charlieDelta: false
                    }
                }));
            } else {
                console.error('Erreur lors de la réinitialisation:', data.message);
            }
        } catch (error) {
            console.error('Erreur lors de la réinitialisation:', error);
        }
    };

    return user ? (
        <StyledContainer>
            <StyledPaper>
                <ActionContainer>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                        <DatePicker
                            label="Sélectionner une date"
                            value={selectedDate}
                            onChange={handleDateChange}
                        />
                    </LocalizationProvider>
                    <Button 
                        variant="contained" 
                        color="primary"
                        onClick={fetchUsers}
                        disabled={!selectedDate || loading}
                    >
                        {loading ? 'Chargement...' : 'Afficher les utilisateurs'}
                    </Button>
                </ActionContainer>

                {loading && (
                    <LoaderContainer>
                        <CircularProgress />
                    </LoaderContainer>
                )}

                <List>
                    {users.map((user) => {
                        const userDetail = userDetails[user.nickname];
                        if (!userDetail) return null;

                        // Vérifier si cet utilisateur a déjà été affiché (pour éviter les doublons)
                        const isFirstWithThisIpMac = users.findIndex(u => 
                            userDetails[u.nickname]?.ipMac === userDetail.ipMac
                        ) === users.indexOf(user);

                        if (!isFirstWithThisIpMac) return null;

                        // Trouver tous les utilisateurs avec la même IP MAC
                        const relatedUsers = users.filter(u => 
                            userDetails[u.nickname]?.ipMac === userDetail.ipMac
                        );

                        return (
                            <StyledBox 
                                key={user.nickname} 
                                sx={{ 
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 1
                                }}
                            >
                                <Box sx={{ 
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2,
                                    marginBottom: '8px'
                                }}>
                                    <Typography 
                                        component="span" 
                                        sx={{
                                            backgroundColor: 'rgba(102, 187, 106, 0.1)',
                                            padding: '4px 8px',
                                            borderRadius: '4px',
                                            border: '1px solid rgba(102, 187, 106, 0.3)',
                                            color: '#66bb6a',
                                            fontWeight: 'bold',
                                            fontSize: '0.9rem',
                                            cursor: 'pointer',
                                            '&:hover': {
                                                backgroundColor: 'rgba(102, 187, 106, 0.2)',
                                            }
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleUserClick(user);
                                        }}
                                    >
                                        {user.nickname}
                                    </Typography>
                                    {relatedUsers.length > 1 && (
                                        <>
                                            <span style={{ color: '#666' }}>→</span>
                                            {relatedUsers
                                                .filter(ru => ru.nickname !== user.nickname)
                                                .map((ru, index) => (
                                                    <Typography 
                                                        key={index}
                                                        component="span"
                                                        sx={{
                                                            backgroundColor: 'rgba(255, 107, 107, 0.1)',
                                                            padding: '4px 8px',
                                                            borderRadius: '4px',
                                                            border: '1px solid rgba(255, 107, 107, 0.3)',
                                                            color: '#ff6b6b',
                                                            fontWeight: 'bold',
                                                            fontSize: '0.9rem',
                                                            cursor: 'pointer',
                                                            '&:hover': {
                                                                backgroundColor: 'rgba(255, 107, 107, 0.2)',
                                                            }
                                                        }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleUserClick(ru);
                                                        }}
                                                    >
                                                        {ru.nickname}
                                                    </Typography>
                                                ))
                                            }
                                        </>
                                    )}
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', alignItems: 'center' }}>
                                    <StatusChip 
                                        label={`IP MAC: ${userDetail.ipMac}`}
                                        className="normal"
                                    />
                                    <StatusChip 
                                        label={`IP Public: ${userDetail.ipPublic}`}
                                        className="normal"
                                    />
                                    <StatusChip 
                                        label={`Banni: ${userDetail.banned ? 'OUI' : 'NON'}`}
                                        className="normal"
                                    />
                                    <StatusChip 
                                        label={`Force Playing: ${userDetail.forcePlaying ? 'OUI' : 'NON'}`}
                                        className="normal"
                                    />
                                    <StatusChip 
                                        label={`Charlie Delta: ${userDetail.charlieDelta ? 'OUI' : 'NON'}`}
                                        className={userDetail.charlieDelta ? 'charlie-delta-active' : 'normal'}
                                        icon={userDetail.charlieDelta ? <WarningIcon>⚠️</WarningIcon> : null}
                                    />
                                </Box>
                            </StyledBox>
                        );
                    })}
                </List>
            </StyledPaper>

            <FullScreenDialog 
                open={openDialog} 
                onClose={() => setOpenDialog(false)}
                fullScreen
            >
                {isRefreshing && (
                    <LinearProgress 
                        sx={{ 
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            zIndex: 9999
                        }} 
                    />
                )}
                <DialogTitle>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">Détails de l'utilisateur: {selectedUser?.nickname}</Typography>
                        <Box display="flex" gap={2}>
                            <Button 
                                variant="outlined"
                                color="primary"
                                onClick={() => fetchUserDetails(selectedUser?.nickname)}
                                startIcon={<RefreshIcon />}
                            >
                                Rafraîchir
                            </Button>
                            <Button 
                                onClick={() => setOpenDialog(false)}
                                color="inherit"
                            >
                                Fermer
                            </Button>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    {selectedUser && userDetails[selectedUser.nickname] && (
                        <>
                            <IPSection>
                                <IPCard elevation={0}>
                                    <span className="ip-label">IP MAC</span>
                                    <span className="ip-value">{userDetails[selectedUser.nickname].ipMac}</span>
                                    <BanButton 
                                        variant="contained"
                                        size="small"
                                        color={userDetails[selectedUser.nickname].banned ? "success" : "error"}
                                        onClick={handleBanUser}
                                    >
                                        {userDetails[selectedUser.nickname].banned ? 'Débannir' : 'Bannir'}
                                    </BanButton>
                                </IPCard>
                                <IPCard elevation={0}>
                                    <span className="ip-label">IP Public</span>
                                    <span className="ip-value">{userDetails[selectedUser.nickname].ipPublic}</span>
                                </IPCard>
                            </IPSection>
                            
                            {userDetails[selectedUser.nickname].charlieDelta && (
                                <CheatDetectedBanner>
                                    <WarningIcon>⚠️</WarningIcon>
                                    CHEAT DÉTECTÉ - Charlie Delta Actif
                                    <WarningIcon>⚠️</WarningIcon>
                                </CheatDetectedBanner>
                            )}
                            
                            <StatusSection>
                                <StatusCard 
                                    active={userDetails[selectedUser.nickname].charlieDelta}
                                    elevation={3}
                                    sx={{
                                        animation: userDetails[selectedUser.nickname].charlieDelta ? 
                                            'pulseBackground 2s infinite' : 'none',
                                        '@keyframes pulseBackground': {
                                            '0%': { backgroundColor: 'rgba(255, 0, 0, 0.1)' },
                                            '50%': { backgroundColor: 'rgba(255, 0, 0, 0.3)' },
                                            '100%': { backgroundColor: 'rgba(255, 0, 0, 0.1)' }
                                        }
                                    }}
                                >
                                    <Typography className="status-title" sx={{
                                        color: userDetails[selectedUser.nickname].charlieDelta ? '#ff4444' : '#fff',
                                        fontWeight: userDetails[selectedUser.nickname].charlieDelta ? 'bold' : 'normal',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: '100%'
                                    }}>
                                        Charlie Delta Status
                                        {userDetails[selectedUser.nickname].charlieDelta && (
                                            <Button
                                                variant="contained"
                                                size="small"
                                                color="error"
                                                onClick={handleResetCharlieDelta}
                                                sx={{
                                                    ml: 2,
                                                    fontSize: '0.75rem',
                                                    height: '24px'
                                                }}
                                            >
                                                RAZ Charlie Delta
                                            </Button>
                                        )}
                                    </Typography>
                                    <Typography className="status-value" sx={{
                                        fontSize: userDetails[selectedUser.nickname].charlieDelta ? '1.4rem' : '1.2rem',
                                        animation: userDetails[selectedUser.nickname].charlieDelta ? 
                                            'pulse 1s infinite' : 'none',
                                        '@keyframes pulse': {
                                            '0%': { transform: 'scale(1)' },
                                            '50%': { transform: 'scale(1.1)' },
                                            '100%': { transform: 'scale(1)' }
                                        }
                                    }}>
                                        {userDetails[selectedUser.nickname].charlieDelta ? 'DÉTECTÉ' : 'NON'}
                                    </Typography>
                                </StatusCard>
                                
                                <StatusCard 
                                    active={!userDetails[selectedUser.nickname].banned}
                                    elevation={3}
                                >
                                    <Typography className="status-title">
                                        Statut du compte
                                    </Typography>
                                    <Typography className="status-value">
                                        {userDetails[selectedUser.nickname].banned ? 'BANNI' : 'ACTIF'}
                                    </Typography>
                                </StatusCard>
                                
                                <StatusCard 
                                    active={userDetails[selectedUser.nickname].forcePlaying}
                                    elevation={3}
                                >
                                    <Typography className="status-title">
                                        Force Playing
                                    </Typography>
                                    <Typography className="status-value">
                                        {userDetails[selectedUser.nickname].forcePlaying ? 'OUI' : 'NON'}
                                    </Typography>
                                </StatusCard>
                            </StatusSection>
                        </>
                    )}

                    <HardwareSection elevation={3}>
                        <Typography 
                            variant="h6" 
                            gutterBottom 
                            sx={{ 
                                fontWeight: 500,
                                color: '#fff',
                                borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                                paddingBottom: '12px'
                            }}
                        >
                            Informations système
                        </Typography>
                        {hardwareDetails ? (
                            <HardwareGrid>
                                <HardwareItem>
                                    <h4>Système</h4>
                                    <p>OS: {hardwareDetails.OSVersion}</p>
                                    <p>Utilisateur: {hardwareDetails.Username}</p>
                                    <p>PC: {hardwareDetails.ComputerName}</p>
                                </HardwareItem>
                                <HardwareItem>
                                    <h4>Processeur</h4>
                                    <p>{hardwareDetails.Processor}</p>
                                </HardwareItem>
                                <HardwareItem>
                                    <h4>RAM</h4>
                                    <p>{hardwareDetails.RAM}</p>
                                </HardwareItem>
                                <HardwareItem>
                                    <h4>Carte graphique</h4>
                                    {hardwareDetails.GraphicsCards.map((card, index) => (
                                        <p key={index}>{card}</p>
                                    ))}
                                </HardwareItem>
                                <HardwareItem>
                                    <h4>Stockage</h4>
                                    {hardwareDetails.DiskDrives.map((drive, index) => (
                                        <p key={index}>{drive}</p>
                                    ))}
                                </HardwareItem>
                                <HardwareItem>
                                    <h4>Carte mère</h4>
                                    <p>{hardwareDetails.Motherboard}</p>
                                    <p>{hardwareDetails.BIOSInfo}</p>
                                </HardwareItem>
                                <HardwareItem>
                                    <h4>Réseau</h4>
                                    {hardwareDetails.NetworkAdapters.map((adapter, index) => (
                                        <p key={index}>{adapter}</p>
                                    ))}
                                </HardwareItem>
                                <HardwareItem>
                                    <h4>Antivirus</h4>
                                    <p>{hardwareDetails.AntivirusInfo}</p>
                                </HardwareItem>
                                <HardwareItem>
                                    <h4>Comptes liés</h4>
                                    {userDetails[selectedUser?.nickname]?.user ? (
                                        Array.isArray(userDetails[selectedUser.nickname].user) ? (
                                            userDetails[selectedUser.nickname].user.map((nickname, index) => (
                                                <p key={index} style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '8px',
                                                    color: nickname === selectedUser.nickname ? '#66bb6a' : '#ff6b6b'
                                                }}>
                                                    {nickname}
                                                    {nickname === selectedUser.nickname ? (
                                                        <Chip 
                                                            size="small" 
                                                            label="ACTUEL" 
                                                            color="success" 
                                                            sx={{ height: '20px' }}
                                                        />
                                                    ) : (
                                                        <Chip 
                                                            size="small" 
                                                            label="ALT" 
                                                            color="error" 
                                                            sx={{ height: '20px' }}
                                                        />
                                                    )}
                                                </p>
                                            ))
                                        ) : (
                                            <p style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '8px',
                                                color: '#66bb6a'
                                            }}>
                                                {userDetails[selectedUser.nickname].user}
                                                <Chip 
                                                    size="small" 
                                                    label="ACTUEL" 
                                                    color="success" 
                                                    sx={{ height: '20px' }}
                                                />
                                            </p>
                                        )
                                    ) : (
                                        <p>Aucun autre compte lié</p>
                                    )}
                                </HardwareItem>
                            </HardwareGrid>
                        ) : (
                            <Box display="flex" justifyContent="center" padding={4}>
                                <CircularProgress size={32} sx={{ color: '#90caf9' }} />
                            </Box>
                        )}
                    </HardwareSection>

                    <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
                        <Tab label="Captures d'écran" />
                        <Tab label="Réseau" />
                        <Tab label="Processus" />
                        <Tab label="Périphériques" />
                    </Tabs>

                    {activeTab === 0 && (
                        <>
                            <StyledImageList 
                                gap={16} 
                                sx={{ 
                                    maxHeight: 'calc(100vh - 300px)',
                                    gridTemplateColumns: 'repeat(auto-fill, 140px) !important'
                                }}
                            >
                                {screenshots.map((screenshot, index) => (
                                    <StyledImageListItem 
                                        key={index}
                                        onClick={() => setSelectedImage(screenshot.fullUrl)}
                                    >
                                        <img
                                            src={screenshot.thumbnailUrl}
                                            alt={`Capture d'écran ${screenshots.length - index}`}
                                            loading="lazy"
                                            style={{ 
                                                width: '140px',
                                                height: '180px',
                                                objectFit: 'cover'
                                            }}
                                        />
                                        <div className="time-overlay">
                                            {screenshot.formattedTime}
                                        </div>
                                    </StyledImageListItem>
                                ))}
                            </StyledImageList>

                            <ImageModal
                                open={!!selectedImage}
                                onClose={() => setSelectedImage(null)}
                            >
                                <Box sx={{
                                    position: 'relative',
                                    maxWidth: '90vw',
                                    maxHeight: '90vh',
                                }}>
                                    {!loadingImages[selectedImage] && (
                                        <Box sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            zIndex: 1
                                        }}>
                                            <CircularProgress size={40} />
                                        </Box>
                                    )}
                                    <img 
                                        src={selectedImage} 
                                        alt="Capture d'écran agrandie"
                                        style={{
                                            maxWidth: '100%',
                                            maxHeight: '90vh',
                                            objectFit: 'contain',
                                            opacity: loadingImages[selectedImage] ? 1 : 0,
                                            transition: 'opacity 0.3s'
                                        }}
                                        onLoad={() => {
                                            setLoadingImages(prev => ({
                                                ...prev,
                                                [selectedImage]: true
                                            }));
                                        }}
                                    />
                                    <IconButton
                                        onClick={() => setSelectedImage(null)}
                                        sx={{
                                            position: 'absolute',
                                            top: -40,
                                            right: -40,
                                            color: 'white'
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Box>
                            </ImageModal>
                        </>
                    )}

                    {activeTab === 1 && (
                        <DataSection>
                            {networkData.map((packet, index) => {
                                const isSuspicious = isItemSuspicious(packet, 'network');
                                return (
                                    <div key={index} className="data-item" style={{
                                        borderColor: isSuspicious ? '#ff4444' : undefined,
                                        borderWidth: isSuspicious ? '2px' : '1px',
                                        backgroundColor: isSuspicious ? 'rgba(255, 68, 68, 0.05)' : undefined,
                                        boxShadow: isSuspicious ? '0 0 10px rgba(255, 68, 68, 0.2)' : undefined
                                    }}>
                                        {isSuspicious && (
                                            <WarningBanner>
                                                ⚠️ Activité réseau suspecte détectée
                                                <Typography variant="caption" sx={{ ml: 'auto', opacity: 0.8 }}>
                                                    Potentielle connexion à un service de triche
                                                </Typography>
                                            </WarningBanner>
                                        )}
                                        {Object.entries(packet).map(([key, value]) => {
                                            const valueStr = String(value);
                                            const isSuspiciousValue = SUSPICIOUS_KEYWORDS.network.some(
                                                keyword => valueStr.toLowerCase().includes(keyword.toLowerCase())
                                            );
                                            return (
                                                <div key={key} className="data-row">
                                                    <span className="data-label">{key}:</span>
                                                    {isSuspiciousValue ? (
                                                        <SuspiciousHighlight>{valueStr}</SuspiciousHighlight>
                                                    ) : (
                                                        <span className="data-value">{valueStr}</span>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </DataSection>
                    )}

                    {activeTab === 2 && (
                        <DataSection>
                            {processData.map((process, index) => {
                                const isSuspicious = isItemSuspicious(process, 'process');
                                return (
                                    <div key={index} className="data-item" style={{
                                        borderColor: isSuspicious ? '#ff4444' : undefined,
                                        borderWidth: isSuspicious ? '2px' : '1px',
                                        backgroundColor: isSuspicious ? 'rgba(255, 68, 68, 0.05)' : undefined,
                                        boxShadow: isSuspicious ? '0 0 10px rgba(255, 68, 68, 0.2)' : undefined
                                    }}>
                                        {isSuspicious && (
                                            <WarningBanner>
                                                ⚠️ Processus suspect détecté
                                                <Typography variant="caption" sx={{ ml: 'auto', opacity: 0.8 }}>
                                                    Possible logiciel de triche ou d'émulation
                                                </Typography>
                                            </WarningBanner>
                                        )}
                                        {Object.entries(process).map(([key, value]) => {
                                            const valueStr = String(value);
                                            const isSuspiciousValue = SUSPICIOUS_KEYWORDS.process.some(
                                                keyword => valueStr.toLowerCase().includes(keyword.toLowerCase())
                                            );
                                            return (
                                                <div key={key} className="data-row">
                                                    <span className="data-label">{key}:</span>
                                                    {isSuspiciousValue ? (
                                                        <SuspiciousHighlight>{valueStr}</SuspiciousHighlight>
                                                    ) : (
                                                        <span className="data-value">{valueStr}</span>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </DataSection>
                    )}

                    {activeTab === 3 && (
                        <DataSection>
                            {controllersData.map((device, index) => {
                                const isSuspicious = isControllerSuspicious(device);
                                return (
                                    <div key={index} className="data-item" style={{
                                        borderColor: isSuspicious ? '#ff4444' : undefined,
                                        borderWidth: isSuspicious ? '2px' : '1px',
                                        backgroundColor: isSuspicious ? 'rgba(255, 68, 68, 0.05)' : undefined,
                                        boxShadow: isSuspicious ? '0 0 10px rgba(255, 68, 68, 0.2)' : undefined
                                    }}>
                                        {isSuspicious && (
                                            <WarningBanner>
                                                ⚠️ Périphérique suspect détecté
                                                <Typography variant="caption" sx={{ ml: 'auto', opacity: 0.8 }}>
                                                    Possible périphérique d'émulation ou de triche
                                                </Typography>
                                            </WarningBanner>
                                        )}
                                        {Object.entries(device).map(([key, value]) => {
                                            const valueStr = String(value);
                                            const isSuspiciousValue = SUSPICIOUS_KEYWORDS.controllers.some(
                                                keyword => valueStr.toLowerCase().includes(keyword.toLowerCase())
                                            );
                                            return (
                                                <div key={key} className="data-row">
                                                    <span className="data-label">{key}:</span>
                                                    {isSuspiciousValue ? (
                                                        <SuspiciousHighlight>{valueStr}</SuspiciousHighlight>
                                                    ) : (
                                                        <span className="data-value">{valueStr}</span>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </DataSection>
                    )}
                </DialogContent>
            </FullScreenDialog>
        </StyledContainer>
    ) : loading ? (
        <LoaderContainer>
            <CircularProgress />
        </LoaderContainer>
    ) : null;
};

export default SkedAC;
