import React from 'react';
import TopInfos from '../components/TopInfos';
import Dispute from './Dispute';
import { List, ListItem, ListItemText, Typography, ThemeProvider, Select, MenuItem, FormControl, InputLabel, RadioGroup, FormControlLabel, Radio, Alert } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import GavelIcon from '@mui/icons-material/Gavel';
import PersonIcon from '@mui/icons-material/Person';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import CustomModal from '../components/CustomModal';
import ExpiredModal from '../components/ExpiredModal';
import Tooltip from '@mui/material/Tooltip';
import { useStartLogic } from '../hooks/useStartLogic';
import {
    Container, LogoContainer, TeamBlock, TeamInfo, Logo, LogoSkeleton,
    TeamTag, TeamStatus, StyledButton, MatchDetails, darkTheme,
    StyledAccordion, StyledAccordionSummary, StyledAccordionDetails,
    TextBlock, ImportantInfo, InfoTitle, InfoList, InfoListItem,
    ContentWrapper, MainContent, ImportantInfo2
} from '../styles/Start.styles';
import HubAdmin from './HubAdmin';

const Start = () => {
    const {
        t,
        user,
        matchData,
        litigeData,
        userTeamName,
        rosters,
        logoLoaded,
        selectedOption,
        openModal,
        expiredToken,
        selectedPlayer,
        arbitrationOptions,
        mossAndMcReq,
        requestFull,
        handleLogoLoad,
        handleOptionChange,
        handleOpenModal,
        handleCloseModal,
        handlePlayerSelection,
        handleProceed,
        isHomeTeam,
        isManager,
        homeTeamNotFound,
        awayTeamNotFound
    } = useStartLogic();

    const modalContent = (
        <>
            <Typography variant="body2" sx={{ mt: 2, mb: 2, color: 'text.primary', fontSize: {xs: '0.9rem', sm: '1rem'} }}>
                <strong>{t('vous_plaidez_pour', { team: userTeamName })}</strong>
            </Typography>
            {(!mossAndMcReq && (selectedOption === 'demande_moss' || selectedOption === 'demande_mc')) ? (
                <Alert severity="error" sx={{ marginTop: '10px' }}>
                    {t('erreur_moss_mc')}
                </Alert>
            ) : (selectedOption === 'demande_moss' || selectedOption === 'demande_mc') ? (
                requestFull ? (
                    <Alert severity="error" sx={{ marginTop: '10px' }}>
                        {t('nombre_de_demandes_depasse')}
                    </Alert>
                ) : (
                    <div>
                        <Typography variant="body2" sx={{ mt: 2, mb: 2, color: 'text.secondary', fontSize: {xs: '0.8rem', sm: '0.9rem'} }}>
                            <div dangerouslySetInnerHTML={{ 
                                __html: t('select_players_for_check', { 
                                    type: selectedOption === 'demande_moss' ? 'MOSS' : 'Monitor Cam' 
                                }) 
                            }} />
                        </Typography>
                        <RadioGroup value={selectedPlayer?.id} onChange={handlePlayerSelection}>
                            {rosters[isHomeTeam ? 'away' : 'home'].map((player, index) => (
                                <FormControlLabel
                                    key={index}
                                    value={player.id}
                                    control={<Radio />}
                                    label={player.nickname}
                                />
                            ))}
                        </RadioGroup>
                    </div>
                )
            ) : null}
            <Typography variant="body2" sx={{ mt: 2, mb: 4, color: 'text.secondary', fontSize: {xs: '0.8rem', sm: '0.9rem'} }}>
                <strong>{t('sollicitation')}</strong> :
                <ul>
                    <li dangerouslySetInnerHTML={{ __html: t('no_discord_solicitation') }} />
                    <li dangerouslySetInnerHTML={{ __html: t('discord_solicitation_penalty') }} />
                    <li dangerouslySetInnerHTML={{ __html: t('arbitrator_response_time') }} />
                </ul>
            </Typography>
        </>
    );

    return (
        <Container hasLitigeData={litigeData !== null}>
            <TopInfos user={user} userTeamName={userTeamName} isManager={isManager} />
            {expiredToken && <ExpiredModal />}
            
            {litigeData ? <Dispute user={user} matchData={matchData} haslitigedata={litigeData} rosters={rosters} isHomeTeam={isHomeTeam} isManager={isManager} /> : (
                <>
                <LogoContainer>
                    <TeamBlock isHome={true}>
                        <TeamInfo>
                            {!logoLoaded.home && <LogoSkeleton variant="rectangular" />}
                            <Logo 
                                src={`https://documents.sked.gg/img?type=team&w=400&h=400&filename=${matchData?.match_team_home_logo}`}
                                onLoad={() => handleLogoLoad('home')}
                                style={{ display: logoLoaded.home ? 'block' : 'none' }}
                            />
                            <TeamTag>{matchData?.match_team_home_tag}</TeamTag>
                            <TeamStatus>{t('home_team_players2')}</TeamStatus>
                        </TeamInfo>
                    </TeamBlock>
                    <strong style={{ color: '#E0E0E0', fontSize: '1.5rem', margin: '0 20px' }}>VS</strong>
                    <TeamBlock isHome={false}>
                        <TeamInfo>
                            {!logoLoaded.away && <LogoSkeleton variant="rectangular" />}
                            <Logo 
                                src={`https://documents.sked.gg/img?type=team&w=400&h=400&filename=${matchData?.match_team_away_logo}`}
                                onLoad={() => handleLogoLoad('away')}
                                style={{ display: logoLoaded.away ? 'block' : 'none' }}
                            />
                            <TeamTag>{matchData?.match_team_away_tag}</TeamTag>
                            <TeamStatus>{t('away_team_players2')}</TeamStatus>
                        </TeamInfo>
                    </TeamBlock>
                </LogoContainer>
                <ContentWrapper>
                    <MainContent>
                        {isManager > 0 && (
                            <ThemeProvider theme={darkTheme}>
                                <FormControl fullWidth style={{ marginBottom: '20px' }}>
                                    <InputLabel id="arbitration-option-label">{t('type_arbitrage')}</InputLabel>
                                    <Select
                                        labelId="arbitration-option-label"
                                        id="arbitration-option-select"
                                        value={selectedOption}
                                        label={t('type_arbitrage')}
                                        onChange={handleOptionChange}
                                    >
                                        {arbitrationOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </ThemeProvider>
                        )}
                        <MatchDetails>
                            <h3>{t('match_details')}</h3>
                            <ThemeProvider theme={darkTheme}>
                                <StyledAccordion defaultExpanded>
                                    <StyledAccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Typography>{t('home_team_players')}</Typography>
                                    </StyledAccordionSummary>
                                    <StyledAccordionDetails>
                                        <List>
                                            {rosters?.home.map((player, index) => (
                                                <ListItem key={index}>
                                                    <ListItemIcon>
                                                      <PersonIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <ListItemText primary={player.nickname} />
                                                    <Tooltip title={t('view_profil')} arrow>
                                                        <IconButton 
                                                            edge="end" 
                                                            aria-label="profile"
                                                            onClick={() => window.open(`https://app.sked.gg/gamer/${player.id}`, '_blank')}
                                                        >
                                                            <OpenInNewIcon fontSize="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </StyledAccordionDetails>
                                </StyledAccordion>
                                <StyledAccordion defaultExpanded>
                                    <StyledAccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography>{t('away_team_players')}</Typography>
                                    </StyledAccordionSummary>
                                    <StyledAccordionDetails>
                                        <List>
                                            {rosters?.away.map((player, index) => (
                                                <ListItem key={index}>
                                                <ListItemIcon>
                                                  <PersonIcon fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText primary={player.nickname} />
                                                <Tooltip title={t('view_profil')} arrow>
                                                    <IconButton 
                                                        edge="end" 
                                                        aria-label="profile"
                                                        onClick={() => window.open(`https://sked.gg/profile/${player.id}`, '_blank')}
                                                    >
                                                        <OpenInNewIcon fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </StyledAccordionDetails>
                                </StyledAccordion>
                            </ThemeProvider>
                        </MatchDetails>
                        {(homeTeamNotFound || awayTeamNotFound) ? (
                            <Alert severity="error" sx={{ marginTop: '10px' }}>
                                {homeTeamNotFound ? t('home_team_not_found') : t('away_team_not_found')}
                            </Alert>
                        ) : isManager > 0 ? (
                            <StyledButton 
                                variant="contained" 
                                onClick={handleOpenModal}
                                disabled={!selectedOption}
                            >
                                <GavelIcon /> &nbsp; {t('demarrer_arbitrage')}
                            </StyledButton>
                        ) : (
                            <Alert severity="error" sx={{ marginTop: '10px' }}>
                                {t('vous_devez_etre_manager')}
                            </Alert>
                        )}

                        <CustomModal
                            open={openModal}
                            onClose={handleCloseModal}
                            title={t('conf_arbitrage')}
                            content={modalContent}
                            onProceed={handleProceed}
                            blur={false}
                        />
                    </MainContent>

                    <TextBlock>
                        <ImportantInfo2 sx={{ backgroundColor: '#333', padding: '15px', borderRadius: '5px' }}>
                            <Typography 
                                variant="h6" 
                                gutterBottom 
                                dangerouslySetInnerHTML={{ __html: t('arbitration_process_title') }} 
                                sx={{ color: '#E0E0E0' }}
                            />
                            <Typography 
                                variant="body2" 
                                paragraph 
                                dangerouslySetInnerHTML={{ __html: t('arbitration_process_description') }} 
                                sx={{ color: '#E0E0E0' }}
                            />
                            <Typography 
                                variant="body2" 
                                paragraph 
                                dangerouslySetInnerHTML={{ __html: t('fair_play_reminder') }} 
                                sx={{ color: '#E0E0E0' }}
                            />
                        </ImportantInfo2>
                        
                        <ImportantInfo sx={{ backgroundColor: '#333', padding: '15px', borderRadius: '5px' }}>
                            <InfoTitle sx={{ color: '#FF4136' }}
                                dangerouslySetInnerHTML={{ __html: t('essential_rules_title') }}
                            />
                            <InfoList>
                                <InfoListItem dangerouslySetInnerHTML={{ __html: t('provide_evidence') }} sx={{ color: '#E0E0E0' }} />
                                <InfoListItem dangerouslySetInnerHTML={{ __html: t('moss_request_time_limit') }} sx={{ color: '#E0E0E0' }} />
                                <InfoListItem sx={{ color: '#E0E0E0' }}>
                                    <span dangerouslySetInnerHTML={{ __html: t('valid_moss_claim') }} />
                                    <InfoList>
                                        <InfoListItem dangerouslySetInnerHTML={{ __html: t('request_in_game_chat') }} />
                                        <InfoListItem dangerouslySetInnerHTML={{ __html: t('record_in_match_sheet') }} />
                                        <InfoListItem dangerouslySetInnerHTML={{ __html: t('create_arbitration_request') }} />
                                    </InfoList>
                                </InfoListItem>
                                <InfoListItem dangerouslySetInnerHTML={{ __html: t('delay_and_forfeit_rule') }} sx={{ color: '#E0E0E0' }} />
                            </InfoList>
                        </ImportantInfo>
                    </TextBlock>
                </ContentWrapper>
                </>
            )}
            
            {user?.data?.user_admin > 0 && <HubAdmin user={user} litigeData={litigeData} />}
        </Container>
    );
};

export default Start;
