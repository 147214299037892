import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const HeaderStyled = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 70px;
  position: relative;
`;

const Logo = styled.img`
  width: 250px;
  height: auto;
  background: linear-gradient(to right, #f93b53 0%, #ff3d4e 7.5%, #dc2f6e 75%, #bb228b 100%);
  margin-top: 70px;
  padding: 10px;
  border-radius: 5px;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-top: 20px;
  color: #E0E0E0;
`;

const Header = () => {
  const { t } = useTranslation();

    return (
        <HeaderStyled>
            <Logo src="../logo.png" alt="logo" />
            <Title>{t('arbitrage')}</Title>
        </HeaderStyled>
    );
};

export default Header;
