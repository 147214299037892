import styled from 'styled-components';
import { Box, Typography, Button, Paper, Chip } from '@mui/material';
import { motion } from 'framer-motion';

export const DarkBox = styled(Paper)(({ theme }) => ({
    backgroundColor: '#121212',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
    borderRadius: '8px',
    padding: '16px',
    marginBottom: '10px'
}));

export const TeamBox = styled(DarkBox)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '200px',
});

export const TeamLogo = styled('img')({
    width: '80px',
    height: '80px',
    objectFit: 'contain',
    marginBottom: '16px',
    marginTop: '15px'
});

export const TeamInfo = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
});

export const TeamNameFlag = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

export const FlagIcon = styled('img')({
    width: '20px',
    height: '14px',
    marginTop: '8px',
    marginBottom: '20px'
});

export const ChatBox = styled(DarkBox)({
    display: 'flex',
    flexDirection: 'column',
    height: '300px',
    overflowY: 'auto',
});

export const TicketItem = styled(Paper)(({ theme }) => ({
    backgroundColor: '#2a2a2a',
    borderRadius: '8px',
    padding: '12px',
    marginBottom: '12px',
    color: 'white',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
}));

export const AuthorName = styled(Typography)({
    fontWeight: 'bold',
    color: 'white',
    display: 'inline',
    marginRight: '8px',
});

export const Timestamp = styled(Typography)({
    color: 'white',
    fontSize: '0.9rem',
    display: 'inline',
    fontStyle: 'italic',
});

export const TicketImage = styled('img')({
    maxWidth: '100px',
    maxHeight: '100px',
    objectFit: 'cover',
    marginTop: '6px',
    marginRight: '6px',
    cursor: 'pointer',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
        transform: 'scale(1.05)',
    },
});

export const ImageContainer = styled(Box)({
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '8px',
    padding: '6px',
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    borderRadius: '6px',
});

export const GradientButton = styled(Button)(({ theme }) => ({
    background: 'linear-gradient(to right, #f93b53 0%, #ff3d4e 7.5%, #dc2f6e 75%, #bb228b 100%)',
    color: 'white',
    '&:hover': {
        background: 'linear-gradient(to right, #f93b53 0%, #ff3d4e 7.5%, #dc2f6e 75%, #bb228b 100%)',
        opacity: 0.9,
    },
}));

export const ReferentName = styled(Typography)({
    fontSize: '1.2rem',
    color: 'white',
    marginTop: '4px',
});

export const StyledChip = styled(Chip)({
  margin: '4px',
  '& .MuiChip-label': {
    paddingLeft: '12px',
    paddingRight: '12px',
  },
});

export const AnimatedText = styled(motion.div)(({ theme, color }) => ({
    fontSize: '1.2rem',
    fontWeight: 'bold',
    textAlign: 'center',
    position: 'relative',
    padding: '20px',
    color: color,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
}));

export const IconTextWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

export const ParticleContainer = styled(motion.div)({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
});

export const Particle = styled(motion.span)(({ theme }) => ({
    position: 'absolute',
    background: 'currentColor',
    borderRadius: '50%',
    width: '5px',
    height: '5px',
}));