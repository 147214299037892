import React from 'react';
import { ContentWrapper } from '../styles/Start.styles';
import { Box, Grid, Typography, TextField, Tooltip, Alert, Button, Menu, MenuItem, CircularProgress, Paper, Dialog, DialogTitle, DialogContent, DialogActions, Select } from '@mui/material';
import { DarkBox, TeamBox, TeamLogo, TeamInfo, TeamNameFlag, ChatBox, TicketItem, AuthorName, Timestamp, TicketImage, ImageContainer, GradientButton, ReferentName, StyledChip, Particle, ParticleContainer, AnimatedText, IconTextWrapper } from '../styles/Dispute.styles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import JoinFullIcon from '@mui/icons-material/JoinFull';
import { useDisputeLogic } from '../hooks/useDisputeLogic';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import GavelIcon from '@mui/icons-material/Gavel';
import EventIcon from '@mui/icons-material/Event';
import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';
import styled from 'styled-components';
import { useArbitrageOptions, getArbitrageOptionText } from '../utils/arbitrageOptions';

const FlagImage = styled.img`
  width: 15px;
  height: 10px;
`;

const Dispute = ({ user, matchData, haslitigedata, rosters, isManager }) => {
    const arbitrationOptions = useArbitrageOptions();

    const {
        t,
        isLoading,
        litigeData,
        tickets,
        newTicket,
        setNewTicket,
        chatBoxRef,
        chatEnabled,
        handleSubmitTicket,
        newTicketImages,
        imageUploadError,
        isUploading,
        uploadEnabled,
        uploadProgress,
        handleImageUpload,
        handleImageClick,
        handleMossUpload,
        canUploadMoss,
        specifics,
        anchorEl,
        open,
        handleClick,
        handleClose,
        requestStatus,
        getStatusText,
        getStatusColor,
        getStatusIcon,
        getParticleColors,
        openPlayerSelectModal,
        setOpenPlayerSelectModal,
        selectedPlayer,
        handlePlayerSelection,
        handleConfirmPlayerSelection,
        getOpponentRoster,
        creatorName,
        handleProceed,
        submitCountdown,
        canJoinHome,
        canJoinAway,
        handleAddArbitrageSubject,
        userCreator,
        isAdmin,
        canAddSubject
    } = useDisputeLogic(user, matchData, haslitigedata, rosters);

    const isArbiter = litigeData?.arbiter_id === user?.data?.user_nickname;
    const isTeamMember = user?.data?.user_nickname === litigeData?.match_team_home_referent || user?.data?.user_nickname === litigeData?.match_team_away_referent;
    const canInteract = isArbiter || isTeamMember;

    if(user?.data?.user_admin < 1) {
        if (!isManager) {
            return (
                <ContentWrapper>
                    <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center', 
                        justifyContent: 'center',
                        gap: 2,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        height: '50vh'
                    }}>
                        <Alert severity="error">
                            {t('accessDenied')}
                        </Alert>
                        {specifics?.some(specific => specific.to_id === user?.data?.user_id) && (
                            <>
                                <Typography variant="h6" color="error">
                                    {t('mossDemandConcernsYou')}
                                </Typography>
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <input
                                        accept=".zip"
                                        style={{ display: 'none' }}
                                        id="icon-button-file-moss-top"
                                        type="file"
                                        onChange={(event) => {
                                            handleMossUpload(event, specifics?.find(specific => specific?.type === 'demande_moss')?.requesting_team);
                                        }}
                                        disabled={canUploadMoss}
                                    />
                                    <label htmlFor="icon-button-file-moss-top">
                                        <Button
                                            variant="contained"
                                            startIcon={<CloudUploadIcon />}
                                            component="span"
                                            disabled={canUploadMoss}
                                            sx={{
                                                background: 'linear-gradient(to right, #f93b53 0%, #ff3d4e 7.5%, #dc2f6e 75%, #bb228b 100%)',
                                                color: 'white',
                                                '&:hover': {
                                                    background: 'linear-gradient(to right, #f93b53 0%, #ff3d4e 7.5%, #dc2f6e 75%, #bb228b 100%)',
                                                    opacity: 0.9,
                                                },
                                            }}
                                        >
                                            {canUploadMoss ? t('mossReceived') : uploadProgress > 0 ? `${t('uploadMoss')} (${uploadProgress}%)` : t('uploadMoss')}
                                        </Button>
                                    </label>
                                </Box>
                            </>
                        )}
                    </Box>
                </ContentWrapper>
            );
        }
    }

    if (isLoading) {
        return (
            <ContentWrapper>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100%' }}>
                    <CircularProgress />
                </Box>
            </ContentWrapper>
        );
    }

    return (
        <ContentWrapper>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {litigeData?.status === 'closed' && (
                        <><DarkBox
                            sx={{
                                marginBottom: '30px',
                                padding: '20px',
                                borderRadius: '8px',
                                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
                                animation: 'pulse 1s infinite',
                                background: '#272727',
                                opacity: 0.7
                            }}
                        >
                            <Grid container spacing={2} alignItems="center" justifyContent="center">
                                <Grid item xs={12}>
                                    <Typography
                                        variant="h5"
                                        component="div"
                                        sx={{
                                            textAlign: 'center',
                                            color: 'white',
                                            fontWeight: 'bold',
                                            marginBottom: 2,
                                            textShadow: '1px 1px 2px rgba(0,0,0,0.3)'
                                        }}
                                    >
                                        <GavelIcon sx={{ marginRight: 1, verticalAlign: 'middle' }} />
                                        {t('arbitrationResult')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Paper elevation={6} sx={{ padding: 2, backgroundColor: 'rgba(255, 255, 255, 0.1)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                                        <Typography variant="body1" color="white">
                                            <EventIcon sx={{ marginRight: 1, verticalAlign: 'middle' }} />
                                            {t('closedOn')}: <strong>{new Date(litigeData?.closed_at).toLocaleString()}</strong>
                                        </Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Paper elevation={6} sx={{ padding: 2, backgroundColor: 'rgba(255, 255, 255, 0.1)', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                                        <Typography variant="body1" color="white">
                                            <PersonIcon sx={{ marginRight: 1, verticalAlign: 'middle' }} />
                                            {t('referee')}: <strong>{litigeData?.arbiter_id}</strong> <FlagImage src="/fr.png" /> 
                                        </Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper elevation={6} sx={{ padding: 2, backgroundColor: 'rgba(255, 255, 255, 0.1)', marginTop: 2, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                                        <Typography variant="h6" color="white" gutterBottom sx={{ textShadow: '1px 1px 2px rgba(0,0,0,0.3)' }}>
                                            <DescriptionIcon sx={{ marginRight: 1, verticalAlign: 'middle' }} />
                                            {t('decision')}
                                        </Typography>
                                        <Typography variant="body1" color="white">
                                            <strong>{litigeData?.result}</strong>
                                        </Typography>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </DarkBox><style>
                                {`
                            @keyframes pulse {
                                0% {
                                    box-shadow: 0 0 0 0 rgba(249, 59, 83, 0.7);
                                }
                                70% {
                                    box-shadow: 0 0 0 10px rgba(249, 59, 83, 0);
                                }
                                100% {
                                    box-shadow: 0 0 0 0 rgba(249, 59, 83, 0);
                                }
                            }
                            `}
                            </style></>
                    )}
                    <DarkBox sx={{ 
                        marginBottom: '30px', 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center',
                        flexWrap: 'wrap'
                    }}>
                        <Typography 
                            variant="h5" 
                            component="span"
                            sx={{ 
                                textAlign: 'center',
                                width: '100%'
                            }}
                        >
                            {getArbitrageOptionText(arbitrationOptions, litigeData?.arbitrage_subject)}
                        </Typography>
                    </DarkBox>
                    {(litigeData?.additional_sujet === null && (!userCreator || isAdmin)) && (
                        <DarkBox>
                            <Button
                                id="add-subject-button"
                                aria-controls={open ? 'add-subject-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                startIcon={<AddIcon />}
                                endIcon={<MoreVertIcon />}
                                sx={{
                                    background: 'linear-gradient(to right, #f93b53 0%, #ff3d4e 7.5%, #dc2f6e 75%, #bb228b 100%)',
                                    color: 'white',
                                    '&:hover': {
                                        background: 'linear-gradient(to right, #f93b53 0%, #ff3d4e 7.5%, #dc2f6e 75%, #bb228b 100%)',
                                        opacity: 0.9,
                                    },
                                }}
                                disabled={!canAddSubject}
                            >
                                {t('addSubject')}
                            </Button>
                            <Menu
                                id="add-subject-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'add-subject-button',
                                }}
                            >
                                {Object.entries(arbitrationOptions).filter(([value]) => value !== 'demande_moss').map(([value, label]) => (
                                    <MenuItem key={value} onClick={() => handleAddArbitrageSubject(value)}>
                                        {label}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </DarkBox>
                    )}
                    {litigeData?.additional_sujet && (
                        <DarkBox sx={{ position: 'relative', overflow: 'hidden' }}>
                            <ParticleContainer>
                                {[...Array(15)].map((_, i) => (
                                    <Particle
                                        key={`particle-${i}`}
                                        animate={{
                                            x: [0, Math.random() * 100 - 50],
                                            y: [0, Math.random() * 100 - 50],
                                            opacity: [0, 1, 0],
                                        }}
                                        transition={{
                                            duration: Math.random() * 5 + 2,
                                            repeat: Infinity,
                                            repeatType: 'reverse',
                                        }}
                                        style={{ 
                                            left: `${Math.random() * 100}%`,
                                            top: `${Math.random() * 100}%`,
                                            color: 'cyan'
                                        }}
                                    />
                                ))}
                            </ParticleContainer>
                            <Typography 
                                variant="h6" 
                                component="span"
                                sx={{ 
                                    textAlign: 'center',
                                    position: 'relative',
                                    zIndex: 1,
                                    padding: '10px',
                                    fontStyle: 'italic'
                                }}
                            >
                                + {getArbitrageOptionText(arbitrationOptions, litigeData.additional_sujet)}
                            </Typography>
                        </DarkBox>
                    )}
                    {(litigeData?.arbitrage_subject === 'demande_moss' || litigeData?.arbitrage_subject === 'demande_mc' || litigeData?.additional_sujet === 'demande_moss' || litigeData?.additional_sujet === 'demande_mc') && (
                        <DarkBox sx={{ marginBottom: '30px', position: 'relative', overflow: 'hidden' }}>
                            <ParticleContainer>
                                {getParticleColors().map((color, colorIndex) => (
                                    [...Array(25)].map((_, i) => (
                                        <Particle
                                            key={`particle-${colorIndex}-${i}`}
                                            animate={{
                                                x: [0, Math.random() * 100 - 50],
                                                y: [0, Math.random() * 100 - 50],
                                                opacity: [0, 1, 0],
                                            }}
                                            transition={{
                                                duration: Math.random() * 5 + 2,
                                                repeat: Infinity,
                                                repeatType: 'reverse',
                                            }}
                                            style={{ 
                                                left: `${Math.random() * 100}%`,
                                                top: `${Math.random() * 100}%`,
                                                color: color
                                            }}
                                        />
                                    ))
                                ))}
                            </ParticleContainer>
                            <Box sx={{ 
                                display: 'flex', 
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: 2 
                            }}>
                                <Box sx={{ display: 'flex', justifyContent: requestStatus.length === 1 ? 'center' : 'space-between', width: '100%' }}>
                                    {requestStatus.map((status, index) => (
                                        <AnimatedText
                                            key={index}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            transition={{ duration: 0.5 }}
                                            color={getStatusColor(status.status)}
                                            sx={{ flex: 1 }}
                                        >
                                            <IconTextWrapper>
                                                {getStatusIcon(status.status)}
                                                <span style={{ marginLeft: '8px' }}>
                                                    {getStatusText(status.status, status.to_nickname, status.type === 'demande_moss')}
                                                </span>
                                            </IconTextWrapper>
                                        </AnimatedText>
                                    ))}
                                </Box>
                                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <input
                                        accept=".zip"
                                        style={{ display: 'none' }}
                                        id="icon-button-file-moss-top"
                                        type="file"
                                        onChange={(event) => {
                                            handleMossUpload(event, specifics?.find(specific => specific?.type === 'demande_moss')?.requesting_team);
                                        }}
                                        disabled={canUploadMoss}
                                    />
                                    <label htmlFor="icon-button-file-moss-top">
                                        <Button
                                            variant="contained"
                                            startIcon={<CloudUploadIcon />}
                                            component="span"
                                            disabled={canUploadMoss}
                                            sx={{
                                                background: 'linear-gradient(to right, #f93b53 0%, #ff3d4e 7.5%, #dc2f6e 75%, #bb228b 100%)',
                                                color: 'white',
                                                '&:hover': {
                                                    background: 'linear-gradient(to right, #f93b53 0%, #ff3d4e 7.5%, #dc2f6e 75%, #bb228b 100%)',
                                                    opacity: 0.9,
                                                },
                                            }}
                                        >
                                            {canUploadMoss ? t('mossReceived') : uploadProgress > 0 ? `${t('uploadMoss')} (${uploadProgress}%)` : t('uploadMoss')}
                                        </Button>
                                    </label>
                                </Box>
                            </Box>
                        </DarkBox>
                    )}
                    <DarkBox>
                        <Typography variant="h6" gutterBottom sx={{ marginBottom: '20px' }} onClick={() => window.open(`https://app.sked.gg/match/${matchData?.match_id}`, '_blank')} style={{ cursor: 'pointer' }}>
                            {t('matchInfo')}
                            <OpenInNewIcon fontSize="small" style={{ marginLeft: '4px' }} />
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="subtitle2" gutterBottom>{t('game')}</Typography>
                                <StyledChip
                                    label={matchData?.match_game_name || t('notSpecified')}
                                    color="primary"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="subtitle2" gutterBottom>{t('ladderName')}</Typography>
                                <StyledChip
                                    label={matchData?.match_ladder_name || t('notSpecified')}
                                    color="secondary"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="subtitle2" gutterBottom>{t('date')}</Typography>
                                <StyledChip
                                    label={matchData?.score_submit_at ? new Date(matchData.score_submit_at).toLocaleDateString(t('locale')) : ''}
                                    color="error"
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </DarkBox>
                </Grid>
                <Grid item xs={12}>
                    <DarkBox>
                        <Typography variant="h6" gutterBottom sx={{ marginBottom: '20px' }}>{t('disputeInfo')}</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="subtitle2" gutterBottom>
                                    {t('disputeCreator')}
                                </Typography>
                                <StyledChip
                                    label={
                                        <span style={{ display: 'flex', alignItems: 'center' }}>
                                            {creatorName || t('unknown')}
                                            <OpenInNewIcon fontSize="small" style={{ marginLeft: '4px' }} />
                                        </span>
                                    }
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => window.open(`https://app.sked.gg/gamer/${litigeData?.creator_id}`, '_blank')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="subtitle2" gutterBottom>{t('ladder')}</Typography>
                                <StyledChip
                                    label={matchData?.match_ladder_id || t('notSpecified')}
                                    color="secondary"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="subtitle2" gutterBottom>{t('assignedReferee')}</Typography>
                                <StyledChip
                                    label={litigeData?.arbiter_id || t('notAssigned')}
                                    color="info"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="subtitle2" gutterBottom>{t('status')}</Typography>
                                <StyledChip
                                    label={litigeData?.status === 'pending' ? t('open') : litigeData?.status === 'closed' ? t('closed') : t('unknown')}
                                    color={litigeData?.status === 'pending' ? 'warning' : 'success'}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="subtitle2" gutterBottom>{t('createdOn')}</Typography>
                                <StyledChip
                                    label={new Date(litigeData?.created_at_lt).toLocaleString(t('locale'))}
                                    color="success"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="subtitle2" gutterBottom>{t('scoresEnteredOn')}</Typography>
                                <StyledChip
                                    label={matchData?.score_submit_at ? new Date(matchData?.score_submit_at).toLocaleString(t('locale')) : t('notProvided')}
                                    color="default"
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </DarkBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TeamBox>
                        <TeamLogo src={`https://documents.sked.gg/img?type=team&w=400&h=400&filename=${matchData?.match_team_home_logo}`} />
                        <TeamInfo>
                            <TeamNameFlag>
                                <Typography 
                                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    onClick={() => window.open(`https://app.sked.gg/team/${matchData?.match_team_home_id}`, '_blank')} 
                                >
                                    {matchData?.team1Name || `${matchData?.match_team_home_tag}`}
                                </Typography>
                            </TeamNameFlag>
                            {canJoinHome && litigeData?.match_team_home_referent === null ? (
                                <Button variant="outlined" color="success" size="small" startIcon={<JoinFullIcon />} onClick={handleProceed} sx={{ marginTop: 2 }}>
                                    {t('join_arbi')}
                                </Button>
                            ) : litigeData?.match_team_home_referent === null ? (
                                <ReferentName>{t('attente_ref')}</ReferentName>
                            ) : (
                                <ReferentName>{litigeData?.match_team_home_referent}</ReferentName>
                            )}
                        </TeamInfo>
                    </TeamBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TeamBox>
                        <TeamLogo src={`https://documents.sked.gg/img?type=team&w=400&h=400&filename=${matchData?.match_team_away_logo}`} />
                        <TeamInfo>
                            <TeamNameFlag>
                                <Typography 
                                    style={{ textDecoration: 'underline', cursor: 'pointer' }} 
                                    onClick={() => window.open(`https://app.sked.gg/team/${matchData?.match_team_away_id}`, '_blank')} 
                                >
                                    {matchData?.team2Name || `${matchData?.match_team_away_tag}`}
                                </Typography>
                            </TeamNameFlag>
                            {canJoinAway && litigeData?.match_team_away_referent === null ? (
                                <Button variant="outlined" color="success" size="small" startIcon={<JoinFullIcon />} onClick={handleProceed} sx={{ marginTop: 2 }}>
                                    {t('join_arbi')}
                                </Button>
                            ) : litigeData?.match_team_away_referent === null ? (
                                <ReferentName>{t('attente_ref')}</ReferentName>
                            ) : (
                                <ReferentName>{litigeData?.match_team_away_referent}</ReferentName>
                            )}
                        </TeamInfo>
                    </TeamBox>
                </Grid>
                <Grid item xs={12}>
                    <Alert variant="outlined" severity="info" sx={{ textAlign: 'center', width: 'fit-content', margin: '0 auto', marginBottom: '10px' }}>
                        {t('warningMessage')}
                    </Alert>
                    <Alert variant="outlined" severity="warning" sx={{ textAlign: 'center', width: 'fit-content', margin: '0 auto', marginBottom: '10px' }}>
                        {t('characterLimitWarning')}
                    </Alert>
                    <ChatBox ref={chatBoxRef}>
                        {chatEnabled ? (
                            tickets.length === 0 ? (
                                <Typography variant="body2" sx={{ textAlign: 'center', margin: '20px 0' }}>
                                    {t('aucun_ticket_publie')}
                                </Typography>
                            ) : (
                                tickets.map((ticket) => (
                                    <TicketItem key={ticket.id} elevation={2}>
                                        <Box sx={{ marginBottom: 0.5 }}>
                                            <AuthorName 
                                                variant="subtitle2" 
                                            >
                                                {ticket.author}
                                            </AuthorName>
                                            <Timestamp variant="caption" component="span">
                                                - {ticket.timestamp || ticket.date}
                                            </Timestamp>
                                        </Box>
                                        <Typography 
                                            variant="body2" 
                                            sx={{ color: (ticket.author !== litigeData?.match_team_home_referent && ticket.author !== litigeData?.match_team_away_referent) ? 'red' : 'inherit' }}
                                        >
                                            {ticket.content}
                                        </Typography>
                                        {ticket.images && ticket.images.length > 0 && (
                                            <ImageContainer>
                                                {Array.isArray(ticket.images) ? (
                                                    ticket.images.map((image, index) => (
                                                        <Tooltip key={index} title={t('voir_image')} arrow>
                                                            <TicketImage
                                                                src={image}
                                                                alt={`${index + 1}`}
                                                                onClick={() => handleImageClick(image)}
                                                            />
                                                        </Tooltip>
                                                    ))
                                                ) : (
                                                    (() => {
                                                        try {
                                                            const imagesArray = JSON.parse(ticket.images);
                                                            return imagesArray.map((image, index) => (
                                                                <Tooltip key={index} title={t('voir_image')} arrow>
                                                                    <TicketImage
                                                                        src={image}
                                                                        alt={`${index + 1}`}
                                                                        onClick={() => handleImageClick(image)}
                                                                    />
                                                                </Tooltip>
                                                            ));
                                                        } catch (error) {
                                                            console.error("Erreur lors de l'analyse des images:", error);
                                                            return null;
                                                        }
                                                    })()
                                                )}
                                            </ImageContainer>
                                        )}
                                    </TicketItem>
                                ))
                            )
                        ) : (
                            <Alert severity="warning" sx={{ textAlign: 'center', margin: '20px 0' }}>
                                {t('chatDisabled')}
                            </Alert>
                        )}
                    </ChatBox>
                    {canInteract && (
                        <Box component="form" onSubmit={handleSubmitTicket} sx={{ mt: 2 }}>
                            {imageUploadError && <Alert severity="error" sx={{ mb: 2 }}>{imageUploadError}</Alert>}
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                value={newTicket}
                                onChange={(e) => setNewTicket(e.target.value)}
                                placeholder={t('yourMessage')}
                                variant="outlined"
                                sx={{ mb: 2 }}
                                required
                            />
                            {uploadEnabled ? (
                                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', mb: 2 }}>
                                    <input
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        id="icon-button-file"
                                        type="file"
                                        multiple
                                        onChange={handleImageUpload}
                                    />
                                    <label htmlFor="icon-button-file">
                                        <GradientButton
                                            variant="contained"
                                            startIcon={<AddPhotoAlternateIcon />}
                                            component="span"
                                            sx={{ mr: { sm: 2 }, mb: { xs: 2, sm: 0 } }}
                                        >
                                            {t('addImages')}
                                        </GradientButton>
                                    </label>
                                    {newTicketImages.length > 0 && (
                                        <Typography variant="body2">
                                            {t('imagesSelected', { count: newTicketImages.length })}
                                        </Typography>
                                    )}
                                </Box>
                            ) : (
                                <Alert severity="warning" sx={{ mb: 2 }}>
                                    {t('uploadDisabled')}
                                </Alert>
                            )}
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <GradientButton 
                                    type="submit" 
                                    variant="contained"
                                    disabled={isUploading || submitCountdown > 0 || !uploadEnabled}
                                    sx={{ 
                                        width: { xs: '100%', sm: 'auto' },
                                        minWidth: '120px',
                                        maxWidth: { sm: '200px' }
                                    }}
                                >
                                    {isUploading ? t('uploading') : submitCountdown > 0 ? `${t('send')} (${submitCountdown})` : t('send')}
                                </GradientButton>
                            </Box>
                        </Box>
                    )}
                </Grid>
            </Grid>
            <Dialog 
                open={openPlayerSelectModal} 
                onClose={() => setOpenPlayerSelectModal(false)}
            >
                <DialogTitle>{t('selectPlayer')}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" gutterBottom>
                        {t('selectPlayerInstruction')}
                    </Typography>
                    <Select
                        value={selectedPlayer}
                        onChange={handlePlayerSelection}
                        fullWidth
                        displayEmpty
                    >
                        <MenuItem value={selectedPlayer} disabled>{t('selectPlayerPlaceholder')}</MenuItem>
                        {getOpponentRoster().map((player) => (
                            <MenuItem key={player.id} value={player.nickname}>
                                {player.nickname}
                            </MenuItem>
                        ))}
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenPlayerSelectModal(false)}>
                        {t('cancel')}
                    </Button>
                    <Button 
                        onClick={handleConfirmPlayerSelection} 
                        color="primary" 
                        disabled={!selectedPlayer}
                    >
                        {t('confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </ContentWrapper>
    );
};


export default Dispute;
