import axios from 'axios';
import qs from 'qs';
import moment from 'moment';

export const API_BASE_URL = 'https://api.sked.gg';
export const API_LTI_URL = 'https://arbitrage-api.sked.gg';

const api = axios.create({
  baseURL: API_BASE_URL
});

const apiLti = axios.create({
  baseURL: API_LTI_URL
});

const handleError = (error, message) => {
  console.error(message, error);
  throw error;
};

export const loginWithToken = async (userToken) => {
  try {
    const response = await api.get(`/users/login-token?user_token=${userToken}`);
    
    if (response.data.status === 'success') {
      const userId = response.data.user_id;
      const userResponse = await api.get(`/users/get-user?user_id=${userId}`);

      return userResponse.data;
    }
    
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getMatchData = async (matchId) => {
    try {
      const response = await api.get(`/matchs/get-match?match_id=${matchId}`);
      return response.data.data;
    } catch (error) {
      handleError('Erreur lors de la récupération des données', error);
    }
};

export const getLitigeData = async (matchId) => {
  
  try {
    const response = await apiLti.get(`/litiges/${matchId}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    }
    handleError(error, 'Erreur lors de la récupération des litiges:');
  }
};


export const getFirstLitigeToHandle = async () => {
  try {
    const response = await apiLti.get(`/litiges`);
    const data = response.data;

    const oldestLitige = data.reduce((oldest, current) => {
      return new Date(oldest.created_at_lt) < new Date(current.created_at_lt) ? oldest : current;
    });
    return oldestLitige;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    }
    handleError(error, 'Erreur lors de la récupération des litiges:');
  }
};


export const fetchNickname = async (userId) => {
  try {
    const response = await api.get(`/users/get-user?user_id=${userId}`);
    return response.data?.data?.user_nickname;
  } catch (error) {
    console.error('Erreur lors de la récupération du nickname:', error);
    return null;
  }
};

export const checkIfManager = async (userId, teamId) => {
  try {
    const { data: { data: teamData } } = await api.get(`/teams/get-team?team_id=${teamId}`);
    
    const player = teamData?.team_roster?.[userId] || null;
    const teamName = teamData?.team_name;

    return {
      isManager: player?.manager,
      teamName: teamName
    };

  } catch (error) {
    console.error('Erreur lors de la vérification du statut de manager:', error);
    throw error;
  }
};

export const checkTeam = async (teamId) => {
  try {
    const response = await api.get(`/teams/get-team?team_id=${teamId}`);

    return response.data;

  } catch (error) {
    console.error('Erreur lors de la vérification de la team:', error);
    throw error;
  }
};

export const createLitige = async (arbitrationDetails) => {
  try {
    const response = await apiLti.post('/litiges', { 
      creator_id: arbitrationDetails.creator_id, 
      match_id: arbitrationDetails.match_id,
      ladder: arbitrationDetails.ladder,
      match_team_home_id: arbitrationDetails.match_team_home_id,
      match_team_home_referent: arbitrationDetails.match_team_home_referent ? arbitrationDetails.creatorNickname : '',
      match_team_away_referent: arbitrationDetails.match_team_away_referent ? arbitrationDetails.creatorNickname : '',
      match_team_away_id: arbitrationDetails.match_team_away_id,
      arbitrage_subject: arbitrationDetails.arbitrage_subject,
      additional_sujet: null,
      local_home: arbitrationDetails.local_home || null,
      local_away: arbitrationDetails.local_away || null,
    });

    return response.data;
  } catch (error) {
    console.error('Erreur lors de la création du litige:', error);
    throw error;
  }
};

export const addSpecificRequest = async (arbitrationDetails, selectedPlayer, isAdditional) => {
  try {
    const response = await apiLti.post('/specific', { 
      match_id: arbitrationDetails.match_id, 
      requesting_team: arbitrationDetails.match_team_home_referent ? arbitrationDetails.match_team_home_id : arbitrationDetails.match_team_away_id,
      to_id: selectedPlayer.id,
      to_nickname: isAdditional ? selectedPlayer : selectedPlayer.nickname,
      type: arbitrationDetails.arbitrage_subject
    });

    return response.data;
  } catch (error) {
    console.error('Erreur in specific request', error);
    throw error;
  }
}

export const fetchCreator = async (creatorId) => {
  try {
    const response = await api.get(`/users/get-user?user_id=${creatorId}`);
    const creatorData = response.data.data.user_nickname;

    return creatorData;
  } catch (error) {
    console.error('Erreur lors de la récupération des données', error);
    throw error;
  }
};

export const createMessage = async (message) => {
  try {
    
    const response = await apiLti.post('/messages', { 
      match_id: message.match_id,
      author: message.author,
      content: message.content,
      images: message.images.length > 0 ? message.images : undefined
    });

    return response.data;

  } catch (error) {
    console.error('Erreur lors de la création du message:', error);
    throw error;
  }
};

export const fetchMessages = async (matchId) => {
  try {
    const response = await apiLti.get(`/messages/${matchId}`);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération des messages:', error);
    throw error;
  }
};

export const uploadPicture = async (files) => {
  try {
    const uploadPromises = files.map(file => {
      const formData = new FormData();
      formData.append('picture', file);
      formData.append('filetype', 'arbitrage');
      
      return axios({
        method: 'POST',
        url: 'https://documents.sked.gg/upload/',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      });
    });

    const response = await Promise.all(uploadPromises);
    const filenames = response.map(res => res.data.filename);

    return filenames;

  } catch (error) {
    console.error('Erreur lors du téléchargement des fichiers:', error);
    throw error;
  }
};

export const checkNbrSpecifics = async (teamId) => {
  try {
    const response = await apiLti.post('/specifics/count', { requesting_team: teamId });

    return response.data;
  } catch (error) {
    console.error('Erreur lors de la création du litige:', error);
    throw error;
  }
}

export const updateLitige = async (litigeId, updates) => { 
  try {
    const response = await apiLti.put(`/litiges/${litigeId}`, updates);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la mise à jour du litige:', error);
    throw error;
  }
};

export const getSpecifics = async (matchId) => {
  try {
    const response = await apiLti.get(`/specifics/match/${matchId}`);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération:', error);
    throw error;
  }
}

export const uploadMossFile = async (file, onUploadProgress) => {
    if (!file) {
        throw new Error('Aucun fichier fourni pour le téléchargement.');
    }

    try {
        const formData = new FormData();
        formData.append('file', file);

        const response = await apiLti.post('/upload-zip', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: onUploadProgress
        });

        if (!response || response.status !== 200) {
            throw new Error('Échec du téléchargement du fichier Moss.');
        }



        return response.data;
    } catch (error) {
        console.error('Erreur lors du téléchargement du fichier Moss:', error);
        throw error;
    }
};

export const uploadFileOnSpecifics = async (fileName, matchId, requesting_team, userTeamId) => {
  try {
    const fileData = {
      submitted_by: userTeamId,
      file: fileName
    };

    const response = await apiLti.post(`/specifics/update-file/${matchId}/${requesting_team}`, fileData);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la mise à jour du fichier pour le Specific:', error);
    throw error;
  }
}

export const getLadderRights = async (userId) => {
  try {
    const response = await api.get(`/users/get-admin-ladders?user_id=${userId}`);

    return response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération des droits ladder:', error);
    return null;
  }
}

export const getLitigationByStatusAndRights = async (userId) => {
  try {
    const ladderRightsResponse = await getLadderRights(userId);
    const ladderRights = ladderRightsResponse ? ladderRightsResponse.data.ladders_ids : [];

    const response = await apiLti.get(`/status-and-rights`, {
      params: {
        ladder_rights: ladderRights
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération des litiges:', error);
    return null;
  }
}

export const getUploadedFiles = async (matchId) => {
  try {
    
    const response = await apiLti.get(`/specifics/match/${matchId}`);

    return response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération des fichiers:', error);
    return null;
  }
}

export const updateStatus = async (matchId, toNickname, status) => {
  try {
    const response = await apiLti.post(`/specifics/update-status/${matchId}/${toNickname}`, { status });
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la mise à jour du statut:', error);
    throw error;
  }
}

export const sendNotification = async (userId, matchId, teamTag, user_token) => {
  try {
    const postData = {
      user_id: userId,
      type: 'matcharbitrage',
      link_type: 'Match',
      link_target_id: matchId,
      params: [teamTag, moment('2024-10-01').toDate()],
      user_token: user_token
    };

    await api.post('/notifications/add-notification/', qs.stringify(postData), {
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      }
    });

  } catch (error) {
    console.error('Erreur lors de l\'envoi de la notification:', error);
    throw error;
  }
}