import React, { useState, useMemo } from 'react';
import { Typography, Box, Link, Paper, Stepper, Step, StepLabel, Button, StepContent, Select, MenuItem } from '@mui/material';
import styled from 'styled-components';
import { useLanguage } from '../contexts/LanguageContext';
import { useTranslation } from 'react-i18next';

const StyledPaper = styled(Paper)`
  padding: 2rem;
  max-width: 800px;
  margin: 2rem auto;
  background-color: #1e1e1e;
  color: #ffffff;
`;

const StyledBox = styled(Box)`
  margin: 1.5rem 0;
`;

const PrerequisBox = styled(Box)`
  background-color: #2d2d2d;
  padding: 1.5rem;
  border-radius: 8px;
  margin: 1rem 0 2rem 0;
  border: 1px solid #3d3d3d;
`;

const StyledLink = styled(Link)`
  color: #64b5f6;
  &:hover {
    color: #90caf9;
  }
`;

const WarningText = styled(Typography)`
  color: #b3b3b3;
  font-style: italic;
  margin-top: 2rem;
  font-size: 0.9rem;
`;

const ResultImage = styled.img`
  max-width: 100%;
  height: auto;
  margin-top: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.5);
`;

const StyledStepper = styled(Stepper)`
  .MuiStepLabel-label {
    color: #ffffff;
  }
  .MuiStepIcon-root {
    color: #64b5f6;
  }
  .MuiStepIcon-text {
    fill: #1e1e1e;
  }
  .MuiStepConnector-line {
    border-color: #3d3d3d;
  }
`;

const StepButtons = styled(Box)`
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
`;

const StepContentWrapper = styled(Box)`
  padding: 1rem 0;
  color: #ffffff;
`;

const LanguageSelect = styled(Select)`
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: #333;
  color: white;
  width: 80px;
  height: 30px;
  font-size: 0.8rem;
  & .MuiSelect-icon {
    color: white;
  }
`;

const FlagMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  background-color: #333;
  color: white;
  &:hover {
    background-color: #444;
  }
`;

const FlagImage = styled.img`
  width: 20px;
  height: 15px;
  margin-right: 5px;
`;

const SkedACSetup = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { language, changeLanguage } = useLanguage();
  const { t } = useTranslation();

  const flags = {
    fr: '/fr.png',
    en: '/gb.png',
    de: '/de.png',
    it: '/it.png',
    es: '/es.png'
  };

  const handleLanguageChange = (event) => {
    changeLanguage(event.target.value);
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const steps = useMemo(() => {
    return t('steps', { returnObjects: true }).map((step, index) => ({
      label: step.label,
      content: (
        <StepContentWrapper key={`step-content-${index}`}>
          <Typography variant="body1" paragraph>
            {step.content}
          </Typography>
        </StepContentWrapper>
      )
    }));
  }, [t]);

  return (
    <StyledPaper elevation={3}>
      <LanguageSelect
        value={language}
        onChange={handleLanguageChange}
        MenuProps={{
          PaperProps: {
            style: {
              backgroundColor: '#333',
            },
          },
        }}
      >
        {Object.entries(flags).map(([code, flagSrc]) => (
          <FlagMenuItem key={code} value={code}>
            <FlagImage src={flagSrc} alt={`Drapeau ${code}`} />
          </FlagMenuItem>
        ))}
      </LanguageSelect>

      <Typography variant="h4" align="center" gutterBottom sx={{ color: '#ffffff' }}>
        {t('title')}
      </Typography>

      <PrerequisBox>
        <Typography variant="h6" gutterBottom sx={{ color: '#ff9800' }}>
          <strong>{t('prerequisites.title')}</strong>
        </Typography>
        <Typography variant="body1" paragraph sx={{ color: '#ffffff' }}>
          {t('prerequisites.description')}
        </Typography>
        <Typography component="ul" sx={{ color: '#ffffff' }}>
          {t('prerequisites.features', { returnObjects: true }).map((feature, index) => (
            <li key={index}>
              <strong>{feature}</strong>
            </li>
          ))}
        </Typography>
        <Typography variant="body2" sx={{ marginTop: 1, color: '#ff9800' }}>
          {t('prerequisites.warning')}
        </Typography>
      </PrerequisBox>

      <StyledStepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={`step-${index}`}>
            <StepLabel>
              <Typography variant="h6" sx={{ color: '#ffffff' }}>
                {step.label}
              </Typography>
            </StepLabel>
            <StepContent>
              {step.content}
              <StepButtons>
                <Button
                  variant="contained"
                  onClick={handleBack}
                  disabled={index === 0}
                  sx={{ bgcolor: '#2d2d2d', '&:hover': { bgcolor: '#3d3d3d' } }}
                >
                  {t('buttons.previous')}
                </Button>
                <Button
                  variant="contained"
                  onClick={index === steps.length - 1 ? handleReset : handleNext}
                  sx={{ bgcolor: '#64b5f6', '&:hover': { bgcolor: '#90caf9' } }}
                >
                  {index === steps.length - 1 ? t('buttons.finish') : t('buttons.next')}
                </Button>
              </StepButtons>
            </StepContent>
          </Step>
        ))}
      </StyledStepper>

      {activeStep === steps.length && (
        <Box sx={{ mt: 3 }}>
          <Typography variant="h5" gutterBottom sx={{ color: '#64b5f6' }}>
            <strong>{t('completion.title')}</strong>
          </Typography>
          <Typography variant="body1" sx={{ color: '#ffffff' }}>
            {t('completion.message')}
          </Typography>
          <Button 
            onClick={handleReset} 
            sx={{ 
              mt: 2, 
              bgcolor: '#2d2d2d', 
              color: '#ffffff',
              '&:hover': { bgcolor: '#3d3d3d' } 
            }}
          >
            {t('buttons.restart')}
          </Button>
        </Box>
      )}

      <WarningText align="center">
        {t('footer.warning')}
      </WarningText>

      <Typography 
        variant="body1" 
        align="center" 
        sx={{ color: '#ffffff', mt: 2, mb: 2 }}
      >
        {t('footer.support')}
      </Typography>

      <Typography 
        variant="body1" 
        align="center" 
        sx={{ color: '#64b5f6', mt: 3, mb: 1 }}
      >
        <strong>{t('footer.result')}</strong>
      </Typography>

      <Box textAlign="center">
        <ResultImage 
          src="/skedac.png" 
          alt="Résultat attendu après installation de Sked AC" 
        />
      </Box>
    </StyledPaper>
  );
};

export default SkedACSetup;
