import React from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import CustomModal from './CustomModal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ExpiredModal = () => {
  const [openModal, setOpenModal] = useState(true);
  const { t } = useTranslation();
  const { matchId, userToken } = useParams();
  
  const modalContent = (
    <>
      <Typography variant="body2" sx={{ mt: 2, mb: 4, color: 'text.secondary', fontSize: {xs: '0.8rem', sm: '0.9rem'} }}>
        <p dangerouslySetInnerHTML={{ __html: t('securityReason') }} />
        <p dangerouslySetInnerHTML={{ __html: t('reconnectInstructions') }} />
        <ol>
          <li dangerouslySetInnerHTML={{ __html: t('step1') }} />
          <li dangerouslySetInnerHTML={{ __html: t('step2') }} />
        </ol>
        <p dangerouslySetInnerHTML={{ __html: t('resumeSession') }} />
      </Typography>
    </>
  );

  const handleProceed = () => {
    window.location.href = `https://app.sked.gg/match/${matchId}`;
  };

  return (
    <CustomModal
      open={openModal}
      title={t('title_expired')}
      content={modalContent}
      onProceed={handleProceed}
      blur={true}
    />
  );
};

export default ExpiredModal;
