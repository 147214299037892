import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchCreator, createMessage, fetchMessages, uploadPicture, updateLitige, getLitigeData, getSpecifics, checkIfManager, uploadMossFile, uploadFileOnSpecifics, addSpecificRequest, updateStatus } from '../services/api';
import { useNavigate } from 'react-router-dom';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, IconButton } from '@mui/material';

const BASE_IMAGE_URL = 'https://documents.sked.gg/img?type=arbitrage&w=1920&h=1080&filename=';

export const useDisputeLogic = (user, matchData, haslitigedata, rosters, isHomeTeam) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [tickets, setTickets] = useState([]);
    const [newTicket, setNewTicket] = useState('');
    const [newTicketImages, setNewTicketImages] = useState([]);
    const [creatorName, setCreatorName] = useState(null);
    const [uploadedImageNames, setUploadedImageNames] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [imageUploadError, setImageUploadError] = useState('');
    const [litigeData, setLitigeData] = useState(haslitigedata);
    const [chatEnabled, setChatEnabled] = useState(true);
    const [uploadEnabled, setUploadEnabled] = useState(true);
    const [lastSubmitTime, setLastSubmitTime] = useState(0);
    const [submitCountdown, setSubmitCountdown] = useState(0);
    const [specifics, setSpecifics] = useState(null);
    const chatBoxRef = useRef(null);
    const submitInterval = 30000;
    const [canJoinHome, setCanJoinHome] = useState(false);
    const [canJoinAway, setCanJoinAway] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [userTeamId, setUserTeamId] = useState(null);
    const [userTeamRole, setUserTeamRole] = useState('');
    const [requestStatus, setRequestStatus] = useState([]);
    const [userCreator, setUserCreator] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [isLoading, setIsLoading] = useState(true);
    const [openPlayerSelectModal, setOpenPlayerSelectModal] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [tempSubject, setTempSubject] = useState(null);
    const [rosterHome, setRosterHome] = useState([]);
    const [rosterAway, setRosterAway] = useState([]);
    const [canUploadMoss, setCanUploadMoss] = useState(false);
    const [canAddSubject, setCanAddSubject] = useState(true);

    useEffect(() => {
        const canUploadMoss = async () => {
            if(litigeData?.arbitrage_subject === 'demande_moss' || litigeData?.arbitrage_subject === 'demande_mc' || litigeData?.additional_sujet === 'demande_moss' || litigeData?.additional_sujet === 'demande_mc') {
                const response = await getSpecifics(litigeData?.match_id);

                if(response.length > 0) {
                    if(response.find(specific => specific.requesting_team !== userTeamId && specific.submitted_by === null && specific.file === null)) {
                        setCanUploadMoss(false);
                    } else {
                        setCanUploadMoss(true);
                    }
                } else {
                    setCanUploadMoss(true);
                }
            } else {
                setCanUploadMoss(true);
            }
        };

        canUploadMoss();
    }, [user, userTeamId]);

    useEffect(() => {
        const canAddSubject = async () => {
            const isCreator = litigeData?.creator_id === user?.data?.user_id;
            const isReferent = litigeData?.match_team_home_referent === user?.data?.user_nickname || 
                              litigeData?.match_team_away_referent === user?.data?.user_nickname;
            const isStatusOpen = litigeData?.status !== 'closed';
            const hasNoAdditionalSubject = !litigeData?.additional_sujet;

            if(hasNoAdditionalSubject && !isCreator && isReferent && isStatusOpen) {
                setCanAddSubject(true);
            } else {
                setCanAddSubject(false);
            }
        };

        canAddSubject();
    }, [user, litigeData]);

    const handleStatusUpdate = async (status, playerName) => {
        await updateStatus(litigeData?.match_id, playerName, status);

        await refreshSpecifics();
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        scrollToBottom();
    }, [tickets]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                if (litigeData) {
                    if (litigeData?.creator_id && user) {
                        const creator = await fetchCreator(litigeData.creator_id);
                        setCreatorName(creator);
                    } else {
                        console.warn("L'utilisateur ou l'ID du créateur est manquant.");
                    }

                    const tickets = await fetchMessages(matchData?.match_id);
                    setTickets(tickets);

                    if(litigeData?.arbitrage_subject === 'demande_moss' || litigeData?.arbitrage_subject === 'demande_mc' || litigeData?.additional_sujet === 'demande_moss' || litigeData?.additional_sujet === 'demande_mc') { 
                        const specifics = await getSpecifics(matchData?.match_id);
                        setSpecifics(specifics);
                    }

                    const teamIds = user?.data?.user_teams || [];
                    const userTeamId = teamIds.includes(matchData?.match_team_home_id) ? matchData?.match_team_home_id : teamIds.includes(matchData?.match_team_away_id) ? matchData?.match_team_away_id : 'none';

                    const player = await checkIfManager(user?.data?.user_id, userTeamId);

                    const teamPosition = teamIds.includes(matchData?.match_team_home_id) ? 'home' : teamIds.includes(matchData?.match_team_away_id) ? 'away' : 'none';

                    player.isManager && teamPosition === 'home' ? setCanJoinHome(true) : setCanJoinHome(false);
                    player.isManager && teamPosition === 'away' ? setCanJoinAway(true) : setCanJoinAway(false);

                    
                    const homeRoster = rosters?.home;
                    const awayRoster = rosters?.away;
                    setRosterHome(homeRoster);
                    setRosterAway(awayRoster);
                }
            } catch (error) {
                console.error("Erreur lors du chargement des données:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [user, litigeData, matchData, navigate]);

    useEffect(() => {
        const teamIds = user?.data?.user_teams || [];
        const currentUserTeamId = teamIds.includes(matchData?.match_team_home_id) ? matchData?.match_team_home_id : teamIds.includes(matchData?.match_team_away_id) ? matchData?.match_team_away_id : null;
        setUserTeamId(currentUserTeamId);

        const currentUserTeamRole = teamIds.includes(matchData?.match_team_home_id) ? 'home' : teamIds.includes(matchData?.match_team_away_id) ? 'away' : '';
        setUserTeamRole(currentUserTeamRole);
    }, [user, matchData]);

    useEffect(() => {
        const lastSubmitTime = parseInt(localStorage.getItem('lastSubmitTime'), 10);
        const currentTime = new Date().getTime();
        const timePassed = currentTime - lastSubmitTime;
        if (timePassed < submitInterval) {
            const timeLeft = Math.floor((submitInterval - timePassed) / 1000);
            setSubmitCountdown(timeLeft);
            const interval = setInterval(() => {
                setSubmitCountdown((currentCountdown) => {
                    const nextCountdown = Math.floor(currentCountdown - 1);
                    if (nextCountdown <= 0) {
                        clearInterval(interval);
                        localStorage.removeItem('lastSubmitTime');
                        return 0;
                    }
                    return nextCountdown;
                });
            }, 1000);
        }
    }, [submitInterval]);
    
    useEffect(() => {
        const isUserReferent = litigeData?.match_team_home_referent === user?.data?.user_nickname || litigeData?.match_team_away_referent === user?.data?.user_nickname || user?.data?.user_admin > 0;
        const isLitigeClosed = litigeData?.status === 'closed';
        setChatEnabled(isUserReferent && !isLitigeClosed);
        setUploadEnabled(isUserReferent && !isLitigeClosed);
    }, [litigeData, user]);

    useEffect(() => {
        if(user?.data?.user_id === litigeData?.creator_id) {
            setUserCreator(true);
        } else {
            setUserCreator(false);
        }
    }, [user?.data?.user_id, litigeData?.creator_id]);

    useEffect(() => {
        if (specifics && Array.isArray(specifics)) {
            const relevantSpecifics = specifics.filter(specific => 
                specific.requesting_team && 
                (specific.type === 'demande_moss' || specific.type === 'demande_mc')
            );
            setRequestStatus(relevantSpecifics.map(specific => ({
                teamId: specific.requesting_team,
                matchId: specific.match_id,
                to_nickname: specific.to_nickname,
                status: specific.status,
                type: specific.type
            })));
        }
    }, [specifics]);

    const getStatusText = (status, playerName, isMoss) => {
        const text = isMoss ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <span>
                    {status === null && t('moss_waiting', { playerName })}
                    {status === 'analyzing' && t('moss_analyzing', { playerName })}
                    {status === 'valid' && t('moss_valid', { playerName })}
                    {status === 'invalid' && t('moss_invalid', { playerName })}
                </span>
                {status === 'analyzing' && user?.data?.user_admin > 0 && (
                    <>
                        <IconButton
                            color="success"
                            onClick={() => handleStatusUpdate('valid', playerName)}
                        >
                            <CheckCircleIcon />
                        </IconButton>
                        <IconButton
                            color="error" 
                            onClick={() => handleStatusUpdate('invalid', playerName)}
                        >
                            <CancelIcon />
                        </IconButton>
                    </>
                )}
            </Box>
        ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <span>
                    {status === null && t('youtube_waiting', { playerName })}
                    {status === 'analyzing' && t('youtube_analyzing', { playerName })}
                    {status === 'valid' && t('youtube_valid', { playerName })}
                    {status === 'invalid' && t('youtube_invalid', { playerName })}
                </span>
                {status === 'analyzing' && user?.data?.user_admin > 0 && (
                    <>
                        <IconButton
                            color="success"
                            onClick={() => handleStatusUpdate('valid', playerName)}
                        >
                            <CheckCircleIcon />
                        </IconButton>
                        <IconButton
                            color="error"
                            onClick={() => handleStatusUpdate('invalid', playerName)}
                        >
                            <CancelIcon />
                        </IconButton>
                    </>
                )}
                {status === null && user?.data?.user_admin > 0 && (
                    <IconButton
                        color="primary"
                        onClick={() => handleStatusUpdate('analyzing', playerName)}
                    >
                        <PlayArrowIcon />
                    </IconButton>
                )}
            </Box>
        );
        return text;
    };

    const getStatusColor = (status) => {
        switch (status) {
            case null: return '#FFA500';
            case 'analyzing': return '#1E90FF';
            case 'valid': return '#32CD32';
            case 'invalid': return '#FF4500';
            default: return '#000000';
        }
    };

    const getStatusIcon = (status) => {
        switch (status) {
            case null:
                return <HourglassEmptyIcon />;
            case 'analyzing':
                return <PendingIcon />;
            case 'valid':
                return <CheckCircleIcon />;
            case 'invalid':
                return <ErrorIcon />;
            default:
                return null;
        }
    };

    const getParticleColors = () => {
        if (requestStatus.length === 1) {
            return [getStatusColor(requestStatus[0].status)];
        } else if (requestStatus.length === 2) {
            return [getStatusColor(requestStatus[0].status), getStatusColor(requestStatus[1].status)];
        }
        return ['#000000'];
    };

    const handleImageUpload = async (event) => {
        setIsUploading(true);
        const files = Array.from(event.target.files);

        if (files.length > 5) {
            setImageUploadError(t('maxImagesExceeded'));
            setIsUploading(false);
            return;
        } else {
            setImageUploadError('');
        }

        const newImages = files.map(file => {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.readAsDataURL(file);
            });
        });

        Promise.all(newImages).then(images => {
            setNewTicketImages(prevImages => [...prevImages, ...images]);
        });

        try {
            const uploadedFileNames = await uploadPicture(files);
            
            setUploadedImageNames(prevNames => [...prevNames, ...uploadedFileNames]);
        } catch (error) {
            console.error("Erreur lors de l'upload des images:", error);
        } finally {
            setIsUploading(false);
        }
    };

    const handleSubmitTicket = async (e) => {
        e.preventDefault();
        const currentTime = new Date().getTime();
        if ((newTicket.trim() || uploadedImageNames.length > 0) && user && (currentTime - lastSubmitTime > submitInterval)) {
            setLastSubmitTime(currentTime);
            localStorage.setItem('lastSubmitTime', currentTime.toString());

            setSubmitCountdown(30);
            let interval = setInterval(() => {
                setSubmitCountdown((currentCountdown) => {
                    if (currentCountdown <= 1) {
                        clearInterval(interval);
                        return 0;
                    }
                    return currentCountdown - 1;
                });
            }, 1000);
            if ((newTicket.trim() || uploadedImageNames.length > 0) && user) {
                const newTicketObj = {
                    match_id: matchData?.match_id,
                    author: user?.data?.user_nickname,
                    content: newTicket.trim(),
                    images: uploadedImageNames.map(name => `${BASE_IMAGE_URL}${name}`),
                    timestamp: new Date().toLocaleString(t('locale'))
                }

                try {
                    await createMessage(newTicketObj);
                    
                    setTickets(prevTickets => [...prevTickets, newTicketObj]);
                    
                    setNewTicket('');
                    setNewTicketImages([]);
                    setUploadedImageNames([]);
                } catch (error) {
                    console.error("Erreur lors de l'envoi du message:", error);
                }
            }
        } else {
            console.error("Vous devez attendre avant de soumettre un nouveau ticket.");
        }
    };

    const scrollToBottom = () => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
    };

    const handleImageClick = (imageUrl) => {
        window.open(imageUrl, '_blank');
    };

    const refreshLitige = async () => {
        const litigeDataResult = await getLitigeData(matchData?.match_id);
        setLitigeData(litigeDataResult);
    }

    const refreshSpecifics = async () => {
        const specificsDataResult = await getSpecifics(matchData?.match_id);
        setSpecifics(specificsDataResult);
    }

    const handleProceed = async () => {
        try {
            const isHomeTeam = user?.data?.user_teams?.includes(matchData?.match_team_home_id);
            const data = {
                [isHomeTeam ? "match_team_home_referent" : "match_team_away_referent"]: user?.data?.user_nickname,
                [isHomeTeam ? "local_home" : "local_away"]: localStorage.getItem('selectedLanguage')
            };

            await updateLitige(litigeData?.id, data);
            await refreshLitige();
        } catch (error) {
            console.log(error);
        }
    }

    const handleMossUpload = async (event, requesting_team) => {
        try {
            const file = event.target.files[0];
            if (!file) {
                console.error("Aucun fichier sélectionné pour l'upload.");
                return;
            }

            const response = await uploadMossFile(file, (progressEvent) => {
                const progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                setUploadProgress(progress);
            });
            
            await uploadFileOnSpecifics(response.path.split('uploads/')[1], matchData?.match_id, requesting_team, userTeamId);

            await refreshSpecifics();

            setUploadProgress(0);
            setCanUploadMoss(true);

        } catch (error) {
            console.error("Erreur lors de l'upload du fichier Moss:", error);
            setUploadProgress(0);
        }
    };

    const handleAddArbitrageSubject = async (subject) => {
        if (subject === 'demande_moss' || subject === 'demande_mc') {
            setTempSubject(subject);
            setOpenPlayerSelectModal(true);
        } else {
            await addSubject(subject);
        }
    };

    const addSubject = async (subject, player = null) => {
        try {
            const data = {
                additional_sujet: subject
            };

            await updateLitige(litigeData?.id, data);

            if (subject === 'demande_moss' || subject === 'demande_mc') {
                let arbitrationDetails = {
                    match_id: matchData?.match_id,
                    match_team_home_id: matchData?.match_team_home_id,
                    [isHomeTeam ? "match_team_home_referent" : "match_team_away_referent"]: user?.data?.user_nickname,
                    match_team_away_id: matchData?.match_team_away_id,
                    arbitrage_subject: subject,
                };

                await addSpecificRequest(arbitrationDetails, player, true);
            }

            await refreshLitige();
            await refreshSpecifics();
        } catch (error) {
            console.log(error);
        }
    };

    const handlePlayerSelection = (event) => {
        setSelectedPlayer(event.target.value);
    };

    const handleConfirmPlayerSelection = async () => {
        if (selectedPlayer) {
            await addSubject(tempSubject, selectedPlayer);
            setOpenPlayerSelectModal(false);
            setSelectedPlayer(null);
            setTempSubject(null);
        }
    };

    const getOpponentRoster = () => {
        if (!matchData || !user) return [];

        const userTeamIds = user?.data?.user_teams || [];
        const isUserHomeTeam = userTeamIds.includes(matchData?.match_team_home_id);
        const isUserAwayTeam = userTeamIds.includes(matchData?.match_team_away_id);

        if (!isUserHomeTeam && !isUserAwayTeam) return [];
        
        const opponentRoster = isUserHomeTeam ? rosterAway : rosterHome;


        return Array.isArray(opponentRoster) ? opponentRoster.filter(player => player && player.nickname) : [];
    };

    return {
        t,
        tickets,
        newTicket,
        setNewTicket,
        newTicketImages,
        creatorName,
        uploadedImageNames,
        isUploading,
        imageUploadError,
        litigeData,
        handleImageUpload,
        handleSubmitTicket,
        handleImageClick,
        refreshLitige,
        handleProceed,
        submitCountdown,
        chatBoxRef,
        chatEnabled,
        uploadEnabled,
        handleMossUpload,
        specifics,
        canJoinHome,
        canJoinAway,
        uploadProgress,
        userTeamId,
        userTeamRole,
        handleAddArbitrageSubject,
        requestStatus,
        userCreator,
        anchorEl,
        open,
        handleClick,
        handleClose,
        getStatusText,
        getStatusColor,
        getStatusIcon,
        getParticleColors,
        isLoading,
        openPlayerSelectModal,
        setOpenPlayerSelectModal,
        handlePlayerSelection,
        handleConfirmPlayerSelection,
        getOpponentRoster,
        selectedPlayer,
        canUploadMoss,
        canAddSubject
    };
};
