import React, { useState, useEffect } from 'react';
import { useLanguage } from '../contexts/LanguageContext';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import PersonIcon from '@mui/icons-material/Person';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Tooltip from '@mui/material/Tooltip';
import { getLadderRights } from '../services/api';

const CustomBadge = styled.span`
  background: linear-gradient(to right, #f93b53 0%, #ff3d4e 7.5%, #dc2f6e 75%, #bb228b 100%);
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
`;

const TeamBadge = styled.span`
  background-color: #4a4a4a;
  color: white;
  padding: 3px 8px;
  border-radius: 8px;
  font-size: 0.8rem;
  margin-left: 10px;
  display: flex;
  align-items: center;
`;

const LanguageSelect = styled(Select)`
  position: absolute;
  background-color: #333;
  color: white;
  width: 80px;
  height: 30px;
  font-size: 0.8rem;
  & .MuiSelect-icon {
    color: white;
  }
  
  @media (max-width: 768px) {
    top: 10px;
    right: 10px;
  }
  
  @media (min-width: 769px) {
    top: 50px;
    left: 10px;
  }
`;

const FlagMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  background-color: #333;
  color: white;
  &:hover {
    background-color: #444;
  }
`;

const FlagImage = styled.img`
  width: 20px;
  height: 15px;
  margin-right: 5px;
`;

const AdminIcon = styled(AdminPanelSettingsIcon)`
  font-size: 1rem;
  margin-left: 5px;
  color: #FFD700;
`;

const TopInfos = ({ user, userTeamName, isManager }) => {
  const { language, changeLanguage } = useLanguage();
  const [ladderRights, setLadderRights] = useState([]);

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    changeLanguage(newLanguage);
  };

  const flags = {
    fr: '/fr.png',
    en: '/gb.png',
    de: '/de.png',
    it: '/it.png',
    es: '/es.png'
  };
  
  useEffect(() => {
    const fetchLadderRights = async () => {
      try {
        const response = await getLadderRights(user?.data?.user_id);
        setLadderRights(response?.data?.ladders_ids);
      } catch (error) {
        console.error("Erreur lors de la récupération des droits des ladders:", error);
      }
    };

    fetchLadderRights();
  }, [user]);

  const renderAdminIcon = () => {
    if (user?.data?.user_admin > 0) {
      return (
        <Tooltip title={
          <div>
            <strong>Droits d'administration :</strong>
            <ul>
              {ladderRights?.map((right, index) => (
                <li key={index}>{right}</li>
              ))}
            </ul>
          </div>
        } arrow>
          <AdminIcon />
        </Tooltip>
      );
    }
    return null;
  };

  return (
    <>
      <CustomBadge>
        <PersonIcon fontSize="small" style={{ marginRight: '5px' }} />
        {user?.data?.user_nickname || <CircularProgress size={20} style={{ color: 'black' }} />}
        {renderAdminIcon()}
        
        {userTeamName !== undefined && isManager > 0 && (
          <TeamBadge>
            {userTeamName || 'Pas d\'équipe'}
          </TeamBadge>
        )}
      </CustomBadge>
      <LanguageSelect
        value={language}
        onChange={handleLanguageChange}
        MenuProps={{
          PaperProps: {
            style: {
              backgroundColor: '#333',
            },
          },
        }}
      >
        {Object.entries(flags).map(([code, flagSrc]) => (
          <FlagMenuItem key={code} value={code}>
            <FlagImage src={flagSrc} alt={`Drapeau ${code}`} />
          </FlagMenuItem>
        ))}
      </LanguageSelect>
    </>
  );
};

export default TopInfos;
