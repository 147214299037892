import React from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const FooterContainer = styled.footer`
  background-color: #1c1c1c;
  color: #ffffff;
  padding: 20px 0;
  text-align: center;
  margin-top: 50px;
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.2);
`;

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Footer = () => {
  const { t } = useTranslation();

  return (
    <FooterContainer>
      <FooterContent>
        <Typography variant="body2" gutterBottom>
          © {new Date().getFullYear()} Sked.gg. {t('tdr')}
        </Typography>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
