import { useTranslation } from 'react-i18next';

export const useArbitrageOptions = () => {
  const { t } = useTranslation();
  
  return {
    demande_moss: t("arbitration_moss_request"),
    technical_problem: t("arbitration_technical_problem"),
    score_contestation: t("arbitration_score_contestation"),
    game_setting_error: t("arbitration_game_setting_error"),
    match_sabotage: t("arbitration_match_sabotage"),
    complice_triche: t("arbitration_cheating_accomplice"),
    complice_ban: t("arbitration_ban_accomplice"),
    discriminatory_behavior: t("arbitration_discriminatory_behavior"),
    boot_ip_flooding: t("arbitration_boot_ip_flooding")
  };
};

export const getArbitrageOptionText = (options, value) => {
  return options[value] || "Option inconnue";
};
