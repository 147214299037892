import React, { useState, useEffect } from 'react';
import { Box, Paper, Button, IconButton, TextField, Badge, Chip, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { getLitigationByStatusAndRights, updateLitige, getMatchData, getUploadedFiles, API_LTI_URL } from '../services/api';
import SearchIcon from '@mui/icons-material/Search';
import { useParams } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ListIcon from '@mui/icons-material/List';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DescriptionIcon from '@mui/icons-material/Description';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';

const HubAdmin = ({ user, litigeData }) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [arbitrationsCount, setArbitrationsCount] = useState(0);
  const [unassignedArbitrationsCount, setUnassignedArbitrationsCount] = useState(0);
  const [isAssigned, setIsAssigned] = useState(false);
  const [isBlinking, setIsBlinking] = useState(false);
  const [redirectToMatchId, setRedirectToMatchId] = useState(null);
  const [assignedArbitrations, setAssignedArbitrations] = useState([]);
  const [teamTags, setTeamTags] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileMenuAnchorEl, setFileMenuAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { matchId, userToken } = useParams();
  const [openCloseModal, setOpenCloseModal] = useState(false);
  const [arbitrationDecision, setArbitrationDecision] = useState('');
  const { t } = useTranslation();
  const [closedArbitrationsCount, setClosedArbitrationsCount] = useState(0);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    window.location.href = `/${event.target.value}/${userToken}`;
  };

  useEffect(() => {
    const fetchArbitrationsCount = async () => {
      try {
        const count = await getLitigationByStatusAndRights(user?.data?.user_id);
        setArbitrationsCount(count.count);

        setUnassignedArbitrationsCount(count.unassignedCount);
        
        const assigned = count.litiges.filter(litige => 
          litige.arbiter_id === user?.data?.user_nickname
        );
        setAssignedArbitrations(assigned);

        const matchIds = assigned.map(arb => arb.match_id);
        const tags = await Promise.all(matchIds.map(matchId => getMatchData(matchId)));
        
        const formattedTags = {};
        tags.forEach((tag, index) => {
          formattedTags[matchIds[index]] = tag;
        });
        setTeamTags(formattedTags);

        const oldestLitige = count.litiges
          .filter(litige => litige.arbiter_id === null)
          .reduce((oldest, current) => {
            return new Date(oldest.created_at_lt) < new Date(current.created_at_lt) ? oldest : current;
          });
        setRedirectToMatchId(oldestLitige.match_id);
        const validLitiges = await Promise.all(
          count.litiges
            .filter(litige => litige.arbiter_id === null)
            .map(async (litige) => {
              try {
                const matchData = await getMatchData(litige.match_id);
                if (matchData) {
                  return litige;
                }
                return null;
              } catch (error) {
                return null;
              }
            })
        );

        // Filtrer les litiges null (ceux avec erreur 404)
        const unassignedLitiges = validLitiges.filter(litige => litige !== null);

        setUnassignedArbitrationsCount(unassignedLitiges.length);

        if (unassignedLitiges.length > 0) {
          const oldestLitige = unassignedLitiges.reduce((oldest, current) => {
            return new Date(oldest.created_at_lt) < new Date(current.created_at_lt) ? oldest : current;
          });
          setRedirectToMatchId(oldestLitige.match_id);
        } else {
          setRedirectToMatchId(null);
        }
        
      } catch (error) {
        console.error("Erreur lors de la récupération des données", error);
      }
    };

    fetchArbitrationsCount();
  }, [user?.data?.user_id, user?.data?.user_nickname]);

  useEffect(() => {
    if (litigeData?.arbiter_id) {
      setIsAssigned(true);
    } else {
      setIsAssigned(false);
    }

    if (!isAssigned) {
      const interval = setInterval(() => {
        setIsBlinking(prev => !prev);
      }, 500);
      return () => clearInterval(interval);
    }
  }, [litigeData, isAssigned]);

  useEffect(() => {
    const fetchUploadedFiles = async () => {
      try {
        const files = await getUploadedFiles(matchId);
        setUploadedFiles(files);
      } catch (error) {
        console.error("Erreur lors de la récupération des fichiers uploadés", error);
      }
    };

    fetchUploadedFiles();
  }, [matchId]);

  const handleAssignment = async () => {
    if ((litigeData?.arbiter_id && litigeData?.arbiter_id !== user?.data?.user_nickname) || litigeData?.arbiter_id === user?.data?.user_nickname) {
      return;
    }
    await updateLitige(litigeData?.id, { arbiter_id: user?.data?.user_nickname });
    setIsAssigned(true);

    window.location.reload();
  };

  const handleGoToUnassignedArbitration = () => {
    window.location.href = `/${redirectToMatchId}/${userToken}`;
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleNavigateToArbitration = (matchId) => {
    window.location.href = `/${matchId}/${userToken}`;
    handleCloseMenu();
  };

  const handleOpenFileMenu = (event) => {
    setFileMenuAnchorEl(event.currentTarget);
  };

  const handleCloseFileMenu = () => {
    setFileMenuAnchorEl(null);
  };

  const handleFileClick = (fileUrl) => {
    window.open(fileUrl, '_blank');
    handleCloseFileMenu();
  };

  const getArbitrationColor = (createdAt) => {
    const diffInDays = (new Date() - new Date(createdAt)) / (1000 * 60 * 60 * 24);
    if (diffInDays < 7) return 'success';
    if (diffInDays < 14) return 'warning';
    return 'error';
  };

  const handleOpenCloseModal = () => {
    setOpenCloseModal(true);
  };

  const handleCloseModal = () => {
    setOpenCloseModal(false);
  };

  const handleCloseArbitration = async () => {
    try {
      await updateLitige(litigeData?.id, { 
        status: 'closed',
        result: arbitrationDecision,
        closed_at: new Date().toISOString()
      });
      setOpenCloseModal(false);
      window.location.reload();
    } catch (error) {
      console.error("Erreur lors de la clôture de l'arbitrage", error);
    }
  };

  return (
    <>
      <Paper 
        elevation={3}
        sx={{
          position: 'fixed',
          top: 0,
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 1000,
          width: isMobile ? '95%' : '900px',
          height: 'auto',
          padding: '10px',
          backgroundColor: '#121212',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
          marginBottom: '15px',
          marginTop: '15px',
          border: '2px solid transparent',
          borderImage: 'linear-gradient(to right, #f93b53 0%, #ff3d4e 7.5%, #dc2f6e 75%, #bb228b 100%)',
          borderImageSlice: 1,
          transition: 'all 0.5s ease-in-out',
          overflow: 'hidden',
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: isMobile ? 'stretch' : 'center',
          gap: isMobile ? 2 : 0,
        }}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            width: isMobile ? '100%' : 'auto',
            justifyContent: isMobile ? 'space-between' : 'flex-start',
          }}>
            <Tooltip title={isAssigned ? t('assignedTo', { arbiter: litigeData?.arbiter_id || t('you') }) : t('assign')}>
              <IconButton 
                sx={{ 
                  color: isAssigned 
                    ? (litigeData?.arbiter_id === user?.data?.user_nickname ? '#4caf50' : '#ffc107')
                    : '#f93b53',
                  opacity: isBlinking ? 0.5 : 1,
                }}
                onClick={handleAssignment}
              >
                {isAssigned ? <AdminPanelSettingsIcon /> : <AssignmentIndIcon />}
              </IconButton>
            </Tooltip>
            <Chip
              label={isAssigned ? t('assignedTo', { arbiter: litigeData?.arbiter_id || t('you') }) : t('unassigned')}
              color={isAssigned ? "success" : "default"}
              size="small"
              sx={{ marginLeft: isMobile ? 0 : 1 }}
            />
            {!isMobile && (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 2 }}>
                  <Tooltip title={t('pendingArbitrations')}>
                    <Chip
                      icon={<AssignmentIcon />}
                      label={arbitrationsCount}
                      color="error"
                      size="small"
                      sx={{ marginRight: 1 }}
                    />
                  </Tooltip>
                  <Tooltip title={t('unassignedArbitrations')}>
                    <Chip
                      icon={<AssignmentIndIcon />}
                      label={unassignedArbitrationsCount}
                      color="warning"
                      size="small"
                      sx={{ marginRight: 1 }}
                    />
                  </Tooltip>
                </Box>
                <Tooltip title={t('chooseUnassignedArbitration')}>
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={handleGoToUnassignedArbitration}
                      sx={{
                        marginLeft: 2,
                        '&.Mui-disabled': {
                          color: 'rgba(255, 255, 255, 0.3)',
                        },
                      }}
                      disabled={unassignedArbitrationsCount === 0}
                      startIcon={<AssignmentTurnedInIcon />}
                    >
                      {t('pick')}
                    </Button>
                  </span>
                </Tooltip>
                <Tooltip title={t('closeArbitration')}>
                  <span>
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={handleOpenCloseModal}
                      sx={{
                        marginLeft: 2,
                        '&.Mui-disabled': {
                          color: 'rgba(255, 255, 255, 0.3)',
                        },
                      }}
                      disabled={!isAssigned || litigeData?.arbiter_id !== user?.data?.user_nickname || litigeData?.status === 'closed'}
                      startIcon={<CheckCircleOutlineIcon />}
                    >
                      {t('close')}
                    </Button>
                  </span>
                </Tooltip>
              </>
            )}
          </Box>
          
          {isMobile && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
              <Tooltip title={t('pendingArbitrations')}>
                <Chip
                  icon={<AssignmentIcon />}
                  label={arbitrationsCount}
                  color="error"
                  size="small"
                />
              </Tooltip>
              <Tooltip title={t('unassignedArbitrations')}>
                <Chip
                  icon={<AssignmentIndIcon />}
                  label={unassignedArbitrationsCount}
                  color="warning"
                  size="small"
              />
              </Tooltip>
              <Tooltip title={t('closedArbitrations')}>
                <Chip
                  icon={<CheckCircleOutlineIcon />}
                  label={closedArbitrationsCount}
                  color="success"
                  size="small"
                />
              </Tooltip>
              <Tooltip title={t('chooseUnassignedArbitration')}>
                <span>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleGoToUnassignedArbitration}
                    sx={{
                      '&.Mui-disabled': {
                        color: 'rgba(255, 255, 255, 0.3)',
                      },
                    }}
                    disabled={unassignedArbitrationsCount === 0}
                    startIcon={<AssignmentTurnedInIcon />}
                  >
                    {t('pick')}
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title={t('closeArbitration')}>
                <span>
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={handleOpenCloseModal}
                    sx={{
                      '&.Mui-disabled': {
                        color: 'rgba(255, 255, 255, 0.3)',
                      },
                    }}
                    disabled={!isAssigned || litigeData?.arbiter_id !== user?.data?.user_nickname || litigeData?.status === 'closed'}
                    startIcon={<CheckCircleOutlineIcon />}
                  >
                    {t('close')}
                  </Button>
                </span>
              </Tooltip>
            </Box>
          )}
          
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: isMobile ? 'space-between' : 'flex-end',
            width: isMobile ? '100%' : 'auto',
            marginTop: isMobile ? 2 : 0,
          }}>
            <Tooltip title={t('myAssignedArbitrations')}>
              <IconButton onClick={handleOpenMenu}>
                <Badge badgeContent={assignedArbitrations.length} color="primary">
                  <ListIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title={t('searchArbitration')}>
              <IconButton onClick={() => setSearchOpen(!searchOpen)}>
                <SearchIcon />
              </IconButton>
            </Tooltip>
            {searchOpen && (
              <TextField
                value={searchTerm}
                onChange={handleSearch}
                placeholder={t('search')}
                variant="outlined"
                size="small"
                sx={{ marginLeft: 1, width: isMobile ? '100%' : '200px' }}
              />
            )}
            {(litigeData?.arbitrage_subject === 'demande_moss' || litigeData?.additional_sujet === 'demande_moss') && (
              <Tooltip title={t('uploadedFiles')}>
                <IconButton onClick={handleOpenFileMenu}>
                  <Badge badgeContent={uploadedFiles?.length} color="secondary">
                    <AttachFileIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Paper>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {assignedArbitrations.map((arbitration) => (
          <MenuItem 
            key={arbitration.match_id} 
            onClick={() => handleNavigateToArbitration(arbitration.match_id)}
            sx={{ backgroundColor: getArbitrationColor(arbitration.created_at_lt) }}
          >
            {teamTags[arbitration.match_id] && (
              <span>{teamTags[arbitration.match_id].match_team_away_tag} vs {teamTags[arbitration.match_id].match_team_home_tag}</span>
            )}
          </MenuItem>
        ))}
        {assignedArbitrations.length === 0 && (
          <MenuItem disabled>{t('noAssignedArbitration')}</MenuItem>
        )}
      </Menu>

      <Menu
        anchorEl={fileMenuAnchorEl}
        open={Boolean(fileMenuAnchorEl)}
        onClose={handleCloseFileMenu}
      >
        {uploadedFiles?.map((file, index) => (
          <MenuItem 
            key={index} 
            onClick={file.file ? () => handleFileClick(`${API_LTI_URL}/download/${file.file}`) : undefined}
          >
            <ListItemIcon>
              <DescriptionIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={`Moss ${file.to_nickname} ${file.file ? '' : ' (En attente)'}`} />
          </MenuItem>
        ))}
        {uploadedFiles?.length === 0 && (
          <MenuItem disabled>{t('noUploadedFile')}</MenuItem>
        )}
      </Menu>

      <Dialog open={openCloseModal} onClose={handleCloseModal}>
        <DialogTitle>{t('closeArbitrationTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('enterFinalDecision')}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="decision"
            label={t('arbitrationDecision')}
            type="text"
            fullWidth
            multiline
            rows={3}
            variant="outlined"
            value={arbitrationDecision}
            onChange={(e) => setArbitrationDecision(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>{t('cancel')}</Button>
          <Button onClick={handleCloseArbitration} disabled={!arbitrationDecision}>{t('close')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default HubAdmin;
