import React, { useEffect } from 'react';

const Home = () => {
    useEffect(() => {
        window.location.href = 'https://app.sked.gg/';
    }, []);

    return (
        <> </>
    );
};

export default Home;
